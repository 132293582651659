import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { theme } from "../../global/styles/theme";
import ReactJson from "react-json-view";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { PreviewApi } from "../../../Api/SocketRulesEngineApi.generated";
import { useOktaAuth } from "@okta/okta-react";
import { UserContext } from "../../../context/providers/UserProvider";
import ToastAlert from "../../global/ToastAlert";

const useStyles = makeStyles({
  previewSampleInputContainer: {
    margin: "5px",
    padding: "25px 25px 25px 25px",
  },
});

const PreviewSampleInput = ({
  sampleJSON,
  setSampleJSON,
  setIsEdited,
  isEdited,
}: any) => {
  const [openToast, setToast] = useState(false);
  const [msg, setMsg] = useState("");
  const classes = useStyles();
  const { clientId } = useContext(UserContext);
  const { oktaAuth, authState } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const previewApi = new PreviewApi();

  const handleChange = (e: any) => {
    setSampleJSON(e.updated_src);
    setIsEdited(true);
  };

  const saveApiSample = () => {
    previewApi.token = token;
    let sampleString = JSON.stringify(sampleJSON);
    if (clientId != null) {
      previewApi
        .setApiSampleData(clientId, sampleString)
        .then((ruleProcessor) => {
          if (ruleProcessor?.apiSample != null) {
            setSampleJSON(JSON.parse(ruleProcessor.apiSample));
            setToast(true);
            setMsg("Input Saved");
            setIsEdited(false);
          }
        })
        .catch((err) => {
          //console.log(err)
        });
    }
  };

  const deleteApiSample = () => {
    previewApi.token = token;
    let sampleString = JSON.stringify(sampleJSON);
    if (clientId != null) {
      previewApi
        .clearApiSampleData(clientId)
        .then((ruleProcessor) => {
          if (ruleProcessor?.apiSchema != null) {
            setSampleJSON(JSON.parse(ruleProcessor.apiSchema));
            setToast(true);
            setMsg("Input Deleted");
            setIsEdited(false);
          }
        })
        .catch((err) => {
          //console.log(err)
        });
    }
  };

  return (
    <Paper elevation={5} className={classes.previewSampleInputContainer}>
      <ToastAlert open={openToast} setToast={setToast} errorMsg={msg} />
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ color: theme.palette.info.main }}>
            Input
          </Typography>
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end" mb={1}>
          <Button
            variant="contained"
            size="small"
            sx={{
              backgroundColor: "primary.main",
              // '&:hover': {
              //     backgroundColor: "#7E908F",
              // },
            }}
            onClick={saveApiSample}
            disabled={!isEdited}
          >
            <SaveAltIcon fontSize="small" sx={{ color: "white" }} />
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              backgroundColor: "#FC6167",
              ml: 1,
              "&:hover": {
                backgroundColor: "#CB3B4A",
              },
            }}
            onClick={deleteApiSample}
          >
            <DeleteIcon fontSize="small" sx={{ color: "white" }} />
          </Button>
        </Grid>
        <Grid
          ml={2}
          item
          xs={12}
          sx={{
            height: 350,
            overflowY: "auto",
            border: 1,
            borderRadius: 1,
            borderColor: "#C6C9CD",
          }}
        >
          <ReactJson
            src={sampleJSON}
            enableClipboard={false}
            displayDataTypes={false}
            onEdit={handleChange}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PreviewSampleInput;
