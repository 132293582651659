import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import { useHistory } from "react-router-dom";
import RouteLeavingDialog from "./RouteLeavingDialog";

type RouteLeavingGuardTypes = {
  when: boolean;
};
const RouteLeavingGuard = ({ when }: RouteLeavingGuardTypes) => {
  const [modalVisible, updateModalVisible] = useState<boolean>(false);
  const [lastLocation, updateLastLocation] = useState<any>();
  const [confirmedNavigation, updateConfirmedNavigation] = useState<any>(false);
  const history = useHistory();

  const shouldBlockNavigation = (location: any) => (when ? true : false);

  const showModal = (location: any) => {
    updateModalVisible(true);
    updateLastLocation(location);
  };

  const closeModal = (cb: any) => {
    updateModalVisible(false);
    if (cb) {
      cb();
    }
  };

  const handleBlockedNavigation = (nextLocation: any) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    closeModal(() => {
      if (lastLocation) {
        updateConfirmedNavigation(true);
      }
    });
  };

  useEffect(() => {
    const navigate = (path: string) => {
      history.push(path);
    };

    if (confirmedNavigation) {
      navigate(lastLocation?.pathname);
      updateConfirmedNavigation(false);
    }
  }, [confirmedNavigation, history, lastLocation?.pathname]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <RouteLeavingDialog
        visible={modalVisible}
        onCancel={closeModal}
        onConfirm={handleConfirmNavigationClick}
      />
    </>
  );
};

export default RouteLeavingGuard;
