import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useOktaAuth } from "@okta/okta-react";
import { useUserContext } from "../../../context/providers/UserProvider";
import { Button, Card, CardActions, CardContent, Popover } from "@mui/material";

type NavbarProps = {
  theme: any;
  redirectPage: (route: string) => void;
};

const useStyles = makeStyles((theme) => ({
  box: {
    flexGrow: 1,
  },
  appBar: {
    bgcolor: "primary.main",
    height: "64px",
    maxHeight: "70px",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
  },
  appBarTitle: {
    color: "white",
    cursor: "pointer",
  },
}));
const Navbar = ({ theme, redirectPage }: NavbarProps) => {
  const { oktaAuth } = useOktaAuth();
  const classes = useStyles();
  const { userInitials, currentUser } = useUserContext();
  const logout = async () => {
    await oktaAuth.signOut();
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box className={classes.box}>
      <AppBar
        position="fixed"
        elevation={0}
        className={classes.appBar}
        style={{
          zIndex: 9999,
        }}
      >
        <Toolbar className={classes.toolbar}>
          <Grid item container>
            <Grid item container xs={6} spacing={2} alignItems="center">
              <Grid item onClick={() => redirectPage("/rules")}>
                <Typography variant="h5" className={classes.appBarTitle}>
                  Socket Rules Engine
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent="flex-end"
              alignItems="center"
              xs={6}
            >
              <Grid item>
                <IconButton
                  size="large"
                  aria-haspopup="true"
                  onClick={handleMenu}
                >
                  <Avatar
                    sx={{
                      backgroundColor: "info.dark",
                      color: "white",
                    }}
                  >
                    {userInitials}
                  </Avatar>
                </IconButton>
                {/* <Menu
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      logout();
                      handleClose();
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu> */}
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Box sx={{ minWidth: 250 }}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography color="text.secondary" gutterBottom>
                          {currentUser?.userName}
                        </Typography>
                        <Typography variant="h5" component="div"></Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          {currentUser?.clientName}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ mb: 1.5 }}
                          color="text.secondary"
                        >
                          {currentUser?.defaultProcessorName}
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ justifyContent: "start" }}>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            logout();
                            handleClose();
                          }}
                        >
                          Logout
                        </Button>
                      </CardActions>
                    </Card>
                  </Box>
                </Popover>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
