import {
  Grid,
  Paper,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import PreviewSampleInput from "./PreviewSampleInput";
import PreviewResult from "./PreviewResults";
import Button from "@mui/material/Button";
import {UserContext} from "../../../context/providers/UserProvider"
import {
    ClientApi,
    PreviewApi,
    ProcessorPreviewDto,
    ProcessorRequestDto,
} from "../../../Api/SocketRulesEngineApi.generated";
import { useOktaAuth } from "@okta/okta-react";
import ToastAlert from "../../global/ToastAlert";


const Preview = ({ redirectPage }: any) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const {currentUser} = useContext(UserContext);
  const { oktaAuth, authState } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const clientApi = new ClientApi();
  const previewApi = new PreviewApi();
  const [ruleProcessor, setRuleProcessor] = useState<ProcessorPreviewDto| null>(null)
  const [sampleJSON, setSampleJSON] = useState({})
  const [testResults, setTestResults] = useState({}) 
  const [isEdited, setIsEdited]= useState <boolean>(false)
  const [openToast, setToast] = useState<boolean>(false);



    const getSampleInput = async (id : number)=>{
         clientApi.token = token
         return await clientApi.getDefaultRuleProcessor(id)
     }
     
     
     
    const getTestResults = async ()=>{
      previewApi.token = token
        
        const data  = {
              "clientName": currentUser?.clientName || '', 
              "processorName" : ruleProcessor?.apiName || '',
              "data":  sampleJSON,
         }
         try {
             // @ts-ignore
             const response = await previewApi.testProcess(false, true, data);
             setTestResults(response);
         } catch (error) {
             console.error(error);
             setToast(true)
         }
     }
 
 
     useEffect(()=>{
         if(currentUser?.clientId != null){
             const fetchSampleInput = (id : number) =>{
                 getSampleInput(id)
                     .then(sampleInput => {
                         setRuleProcessor(sampleInput)
                     })
                     .catch((err => {
                     console.log(err)
                 }))
             }
             fetchSampleInput(currentUser.clientId)
         }
     },[currentUser?.clientId])

    useEffect(()=>{
             if( ruleProcessor?.apiSample != null && ruleProcessor?.apiSample != ""){
                  let data = ruleProcessor ? JSON.parse(ruleProcessor?.apiSample) : {}
                  setSampleJSON(data)
             }else{
                  let data = ruleProcessor ? JSON.parse(ruleProcessor?.apiSchema as string) : {}
                  setSampleJSON(data)
             }
    },[ruleProcessor])
 

  return (
    <Grid item container xs={12} width="100%">
        <ToastAlert
            open={openToast}
            setToast={setToast}
            errorMsg={"Invalid Data"}
        />
      <Paper
        elevation={10}
        sx={{
          padding: "40px 40px 30px 40px",
          width: "100%",
          minHeight: "calc(100vh - 120px)",
        }}
      >
        <Grid item container spacing={5}>
          <Grid item xs={12}>
            <Typography variant="h4">Preview</Typography>
          </Grid>
          <Grid item xs={12}>
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={isLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                      <PreviewSampleInput sampleJSON={sampleJSON} setSampleJSON={setSampleJSON} isEdited={isEdited} setIsEdited={setIsEdited}/>
                  </Grid>
                  <Grid container item xs={12} justifyContent='flex-end' mr={2}>
                      <Button
                          variant="contained"
                          // disabled={!isEdited}
                          onClick={getTestResults}
                      >
                          Test
                      </Button>
                  </Grid>
                  <Grid item xs={12}>
                      <PreviewResult testResults={testResults}/>
                  </Grid>
              </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Preview;
