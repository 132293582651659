import * as React from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DateTime } from "luxon";
import { ApiLogDto } from "../../../Api/SocketRulesEngineApi.generated";
import { theme } from "../../global/styles/theme";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Chip from "@mui/material/Chip";
import {
  formatCurrency,
  formatNumber,
} from "../../../utilities/numberFormatUtility";

const RuleApplicationHistoryRow = ({
  logData,
  openRule,
}: {
  logData: ApiLogDto;
  openRule: any;
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset !important" } }}
        style={
          open
            ? { backgroundColor: "rgba(119, 123, 131, .1)" }
            : { backgroundColor: "white" }
        }
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell onClick={() => setOpen(!open)} sx={{ cursor: "pointer" }}>
          {logData.ruleDetails?.ruleGroupName}
        </TableCell>
        <TableCell onClick={() => setOpen(!open)} sx={{ cursor: "pointer" }}>
          {logData.conditionMatch}
        </TableCell>
        <TableCell onClick={() => setOpen(!open)} sx={{ cursor: "pointer" }}>
          {logData.ruleDetails?.modificationString}
        </TableCell>
        <TableCell
          onClick={() => setOpen(!open)}
          sx={{ cursor: "pointer", color: theme.palette.text.secondary }}
          width={250}
        >
          <Grid container sx={{ width: 160 }}>
            <Grid item xs={6}>
              {logData.requestDate?.toLocaleString(DateTime.DATE_SHORT)}
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              {logData.requestDate?.toLocaleString(DateTime.TIME_WITH_SECONDS)}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell width={50}>
          <IconButton onClick={() => openRule(logData)}>
            <ReadMoreIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={
            open
              ? {
                  paddingBottom: 0,
                  paddingTop: 0,
                  backgroundColor: "rgba(119, 123, 131, .1)",
                }
              : { paddingBottom: 0, paddingTop: 0 }
          }
          colSpan={6}
        >
          <Collapse
            in={open}
            timeout={{ appear: 100, enter: 10, exit: 0 }}
            unmountOnExit
          >
            <Grid container>
              <Grid item xs={2} />
              <Grid item xs={8}>
                <Box sx={{ margin: 1 }} style={{ backgroundColor: "white" }}>
                  <Typography variant="h6" sx={{ ml: 3 }}>
                    Quotes
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: theme.palette.primary.main }}>
                          Carrier Code
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: theme.palette.primary.main }}
                        >
                          Quote ID
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: theme.palette.secondary.main }}
                        >
                          Original Value
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: theme.palette.secondary.main }}
                        >
                          Modified Value
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {logData.metadata?.additionalDataRows != null
                        ? Object.entries(
                            logData.metadata?.additionalDataRows
                          ).map((data) => {
                            return (
                              <TableRow key={data[0]}>
                                <TableCell>
                                  <Chip
                                    sx={{
                                      backgroundColor:
                                        theme.palette.primary.main,
                                      color: "white",
                                    }}
                                    label={data[1][0]}
                                  />
                                </TableCell>
                                <TableCell align="right">
                                  {data[1][1]}
                                </TableCell>
                                <TableCell align="right">
                                  {formatCurrency(data[1][2])}
                                </TableCell>
                                <TableCell align="right">
                                  {formatCurrency(data[1][3])}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        : null}
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default RuleApplicationHistoryRow;
