import {
  Avatar,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  OperationDto,
  RuleBuilderFieldDto,
} from "../../../../Api/SocketRulesEngineApi.generated";
import { useState } from "react";
import { FormikProps } from "formik";
import { INewRuleForm } from "./NewRuleCard";

const NewModification = ({
  options,
  formik,
}: {
  options: RuleBuilderFieldDto[];
  formik: FormikProps<INewRuleForm>;
}) => {
  const [operations, setOperations] = useState<OperationDto[]>([]);

  const handleModificationChange = (event: SelectChangeEvent) => {
    const modificationId = event.target.value;
    formik.setFieldValue("modificationId", modificationId, true);

    let selectedOption = options.find(
      (c) => modificationId == c.fieldId?.toString()
    );

    let modificationOperations =
      selectedOption?.availableOperations?.filter(
        (o) => o.allowedAsModification
      ) ?? [];

    setOperations(modificationOperations);
    console.log("operations", modificationOperations);
    if (modificationOperations.length === 1) {
      formik.setFieldValue(
        "modificationOperation",
        modificationOperations[0].operationId?.toString(),
        true
      );

      setTimeout(() => formik.setFieldTouched("modificationOperation", true));
    } else {
      formik.setFieldValue("modificationOperation", "", true);
    }
  };

  const handleOperationChange = (event: SelectChangeEvent) => {
    formik.setFieldValue("modificationOperation", event.target.value, true);
  };

  return (
    <Grid container>
      <Grid item xs={5} sx={{ px: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="modification-operation-select-label">
            operation
          </InputLabel>
          <Select
            labelId="modification-operation-select-label"
            id="modification-operation-select"
            value={formik.values.modificationOperation}
            label="operation"
            onChange={handleOperationChange}
            disabled={operations.length < 1}
          >
            {operations.map((o) => (
              <MenuItem
                key={`operation_${o.operationId}`}
                value={o.operationId?.toString() ?? ""}
              >
                {o.operationDisplay}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        to
      </Grid>
      <Grid item xs={5} sx={{ px: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="modification-select-label">Field</InputLabel>
          <Select
            labelId="modification-select-label"
            id="modification-select"
            value={formik.values.modificationId}
            label="Field"
            onChange={handleModificationChange}
          >
            {options.map((o) => (
              <MenuItem
                key={`field_${o.fieldId}`}
                value={o.fieldId?.toString() ?? ""}
              >
                {o.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default NewModification;
