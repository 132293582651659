import { AxiosRequestConfig } from "axios";
export class SocketRulesEngineApiBase {
  public token: any = null;
  async transformOptions(
    options: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> {
    if (this.token != null) {
      options.headers = {
        ...options.headers,
        Authorization: "Bearer " + this.token,
      };
    }
    return Promise.resolve(options);
  }
  getBaseUrl(url: string): string {
    return process.env.REACT_APP_API_BASE_URL ?? "http://localhost:404";
  }
}
