import React, { useEffect } from "react";
// import dotenv from "dotenv";
import config from "./authentication/okta/config";
import { CssBaseline } from "@mui/material";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import { theme } from "./components/global/styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import { UserProvider } from "./context/providers/UserProvider";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import About from "./components/pages/about/About";
import Layout from "./components/global/styles/Layout";
import LoginPage from "./components/pages/login/LoginPage";
import PageNotFound from "./components/pages/404/PageNotFound";
import RuleDetailSkeleton from "./components/pages/rule-details/skeleton/RuleDetailSkeleton";
import Rules from "./components/pages/rules/Rules";
import Spinner from "./components/global/loading/Spinner";
import RuleApplicationHistory from "./components/pages/history/RuleApplicationHistory";
import { useFlags } from "launchdarkly-react-client-sdk";
import { appConfig } from "./adapters/app-config";
import Preview from "./components/pages/preview/Preview";
import Settings from "./components/pages/settings/Settings";
import NewRuleCard from "./components/pages/rule-details/new/NewRuleCard";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

export const oktaAuth = new OktaAuth(config.oidc);

const App = () => {
  const history = useHistory();
  const launchDarklyFeatureFlag = useFlags();
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  const redirectPage = (route: string) => {
    history.push(route);
  };

  useEffect(() => {
    console.log(`Env: ${appConfig.ENVIRONMENT}`);

    console.log(
      "Flag test in App.tsx:",
      launchDarklyFeatureFlag["sru-45-test-flag"]
    );
  }, []);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <UserProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Switch>
              <Route path="/" exact={true} component={LoginPage} />
              <Route
                path="/login/callback"
                component={() => <LoginCallback loadingElement={<Spinner />} />}
              />
              <SecureRoute
                exact={true}
                path="/rules"
                component={() => (
                  <Layout theme={theme} redirectPage={redirectPage}>
                    {<Rules redirectPage={redirectPage} />}
                  </Layout>
                )}
              />
              <SecureRoute
                path="/rule/new"
                component={() => (
                  <Layout theme={theme} redirectPage={redirectPage}>
                    {<NewRuleCard redirectPage={redirectPage} />}
                  </Layout>
                )}
              />
              <SecureRoute
                path="/rule/:ruleGroupUrl"
                component={() => (
                  <Layout theme={theme} redirectPage={redirectPage}>
                    {<RuleDetailSkeleton redirectPage={redirectPage} />}
                  </Layout>
                )}
              />
              {launchDarklyFeatureFlag["sru-api-log-history"] ? (
                <SecureRoute
                  path="/history"
                  component={() => (
                    <Layout theme={theme} redirectPage={redirectPage}>
                      {<RuleApplicationHistory redirectPage={redirectPage} />}
                    </Layout>
                  )}
                />
              ) : null}

              {launchDarklyFeatureFlag["sru-api-configuration"] ? (
                <SecureRoute
                  path="/settings"
                  component={() => (
                    <Layout theme={theme} redirectPage={redirectPage}>
                      {<Settings redirectPage={redirectPage} />}
                    </Layout>
                  )}
                />
              ) : null}

              {launchDarklyFeatureFlag["sru-api-preview"] ? (
                <SecureRoute
                  path="/preview"
                  component={() => (
                    <Layout theme={theme} redirectPage={redirectPage}>
                      {<Preview redirectPage={redirectPage} />}
                    </Layout>
                  )}
                />
              ) : null}

              <Route
                path="/about"
                exact={true}
                component={() => <About redirectPage={redirectPage} />}
              />
              <Route
                path="/404"
                component={() => <PageNotFound redirectPage={redirectPage} />}
              />
              <Route component={() => <Redirect to="/404" />} />
            </Switch>
          </ThemeProvider>
        </LocalizationProvider>
      </UserProvider>
    </Security>
  );
};

export default App;
