import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Drawer from "@mui/material/Drawer";
import Paper from "@mui/material/Paper";
import { theme } from "../../global/styles/theme";
import { useOktaAuth } from "@okta/okta-react";
import {
  ApiLogDto,
  LogsApi,
} from "../../../Api/SocketRulesEngineApi.generated";
import RuleApplicationHistoryRow from "./RuleApplicationHistoryRow";
import RuleDetailPanel from "./RuleDetailPanel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "1rem",
  },
}));

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const RuleApplicationHistory = ({ redirectPage }: any) => {
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const logsApi = new LogsApi();

  const [logs, setLogs] = useState<ApiLogDto[]>([]);

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [total, setTotal] = useState<number>(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [isLoading, setLoading] = useState<boolean>(false);

  const getLogs = async () => {
    logsApi.token = token;
    let logs = await logsApi.getRuleHistory(rowsPerPage, rowsPerPage * page);

    return logs;
  };
  const [openedRule, setOpenedRule] = useState<ApiLogDto | null>(null);

  const tempSetRule = (rule: ApiLogDto | null) => {
    setOpenedRule(rule);
  };

  const fetchData = async () => {
    setLoading(true);

    const logs = await getLogs();

    setLogs(logs.currentPage ?? []);
    setTotal(logs.total ?? 0);

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [rowsPerPage, page]);

  return (
    <Grid item container xs={12} width="100%">
      <Paper
        elevation={10}
        sx={{
          padding: "40px 40px 30px 40px",
          width: "100%",
          minHeight: "calc(100vh - 120px)",
        }}
      >
        <Grid item container spacing={5}>
          <Grid item xs={12}>
            <Typography variant="h4">Rule Application History</Typography>
          </Grid>
          <Grid item xs={12}>
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={isLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            {logs.length > 0 ? (
              <TableContainer component={Paper} sx={{ minWidth: 700 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell />
                      <StyledTableCell
                        sx={{
                          color: theme.palette.primary.light,
                        }}
                      >
                        Rule
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          color: theme.palette.primary.light,
                        }}
                      >
                        Matched Condition
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          color: theme.palette.secondary.light,
                        }}
                      >
                        Modification
                      </StyledTableCell>
                      <StyledTableCell>Date</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logs.map((log) => {
                      return (
                        <RuleApplicationHistoryRow
                          key={log.apiLogId}
                          logData={log}
                          openRule={tempSetRule}
                        ></RuleApplicationHistoryRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow sx={{ "& p": { marginBottom: 0 } }}>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        colSpan={5}
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            "aria-label": "rows per page",
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            ) : (
              <Typography>
                {isLoading
                  ? "Loading rule application history ... "
                  : "No rule application history yet."}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Paper>

      <Drawer
        PaperProps={{
          sx: { width: "500px", pt: "80px", pl: 4, pr: 1 },
        }}
        variant="temporary"
        ModalProps={{ keepMounted: false, onClose: () => setOpenedRule(null) }}
        anchor="right"
        open={openedRule !== null}
      >
        <RuleDetailPanel
          rule={openedRule}
          closeRule={setOpenedRule}
        ></RuleDetailPanel>
      </Drawer>
    </Grid>
  );
};

export default RuleApplicationHistory;
