import React from 'react';

const About = ({ redirectPage }: any) => {
    return (
        <h1>
            About
        </h1>
    )
}

export default About;