import { createContext, useContext, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { requestUserProfile } from "../../adapters/okta/requestUserProfile";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { UserApi, UserDto } from "../../Api/SocketRulesEngineApi.generated";
import { User } from "oidc-client";

export interface IUserContext {
  currentUser: UserDto | null;
  currentUserProfile: any | null;
  userInitials: string;
  createUserInitials: (user: any) => void;
  token: string | undefined;
  clientId: number | null;
}

export const UserContext = createContext<IUserContext>({
  currentUser: null,
  currentUserProfile: {},
  userInitials: "",
  createUserInitials: (user) => {},
  token: undefined,
  clientId: null,
});

export const useUserContext = () => {
  const currentUserContext = useContext(UserContext);
  if (!currentUserContext) {
    throw new Error("Cannot obtain user context outside of UserProvider");
  }
  return currentUserContext;
};

const UserProviderContext = ({ children }: any) => {
  const [currentUser, setUser] = useState<UserDto | null>(null);
  const [currentUserProfile, setUserProfile] = useState({});
  const [userInitials, setUserInitials] = useState("");
  const [clientId, setClientId] = useState<number | null>(null);
  const { oktaAuth, authState } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const ldClient = useLDClient();
  const userApi = new UserApi();
  

  const createUserInitials = (user: any) => {
    const initials = user.given_name[0] + user.family_name[0];
    return initials;
  };

  const setLaunchDarklyContext = (userDto: UserDto) => {
    if (userDto != null) {
      ldClient?.identify({
        key: userDto.userName ?? "",
        email: userDto.userName ?? "",
      });
    }
  };

  const getUserFromApi = async (email: string) => {
    userApi.token = token;
    let user = await userApi.getUserByUsername(email);
    return user;
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      const fetchUserProfile = () => {
        requestUserProfile(token)
          .then((userProfile) => {
            setUserProfile(userProfile);
            setUserInitials(createUserInitials(userProfile));
            return getUserFromApi(userProfile.email);
          })
          .then((user) => {
            setClientId(user.clientId ?? 0);
            setUser(user);
            setLaunchDarklyContext(user);
          })
          .catch((err) => {
            console.error(err);
          });
      };

      fetchUserProfile();
    }
  }, [token, authState]);

  return (
    <UserContext.Provider
      value={{
        currentUser,
        currentUserProfile,
        createUserInitials,
        userInitials,
        token,
        clientId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserProvider = UserProviderContext;
