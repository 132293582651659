import React, { useState } from "react";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import {
  RuleGroupDisplayDto,
  RuleGroupTemplateDto,
} from "../../../../Api/SocketRulesEngineApi.generated";
import { theme } from "../../../global/styles/theme";
import Typography from "@mui/material/Typography";
import { ConditionOperationType } from "../../../../utilities/OperationTypes";

const useStyles = makeStyles({
  ruleDefaultContainer: {
    margin: "5px",
    // padding: "10px",
    padding: "25px 25px 25px 25px",
  },
});

const conditionTemplate = (conditions: RuleGroupTemplateDto) => {};

const RuleTemplateCard = ({ rule }: any) => {
  const classes = useStyles();
  const [ruleDto] = useState<RuleGroupDisplayDto>(rule);

  const MatchingPrefix = (template?: RuleGroupTemplateDto) => {
    if (template == null || template.conditions == null) {
      return "";
    }

    if (template.conditions.length === 1) {
      switch (template.conditions[0].conditionOperationId) {
        case ConditionOperationType.Boolean:
          return "as assigned below,";
        case ConditionOperationType.DateIsInRange:
        case ConditionOperationType.NumberIsInRange:
          return "of an assignment below,";
        case ConditionOperationType.StringArrayEquals:
        default:
          return "an assignment below,";
      }
    } else if (template.conditions.length > 1) {
      return "match an assignment below,";
    }

    return "an assignment below,";
  };

  return (
    <Paper elevation={5} className={classes.ruleDefaultContainer}>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ color: theme.palette.info.main }}>
            Rule Template
          </Typography>
        </Grid>

        <Grid item container sx={{ pl: 8, mb: 3 }} direction="row">
          <Grid item container xs={"auto"} sx={{ mt: 2 }} alignItems="center">
            <Grid item>
              <Typography>When</Typography>
            </Grid>
            <Grid
              item
              container
              direction={"column"}
              sx={{ width: "auto" }}
              flexWrap="nowrap"
            >
              {ruleDto?.template?.conditions?.map((c, index) => {
                return (
                  <Grid
                    item
                    container
                    sx={{ mt: index > 0 ? 1 : 0 }}
                    flexWrap="nowrap"
                    flexGrow={0}
                    key={`condition_field_${c.conditionFieldId}`}
                  >
                    <Chip
                      label={c.conditionField}
                      size="small"
                      sx={{
                        backgroundColor: theme.palette.primary.light,
                        color: "white",
                        mx: 1,
                      }}
                    />
                    <Typography
                      noWrap
                      component="span"
                      sx={{
                        color: theme.palette.primary.light,
                        fontWeight: "bold",
                        mr: 1,
                      }}
                    >
                      {c.conditionOperation}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
            <Grid item>
              <Typography noWrap>
                {MatchingPrefix(ruleDto?.template)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            sx={{ width: "auto", mt: 2 }}
            alignItems="center"
          >
            <Grid item>
              <Typography
                component="span"
                sx={{
                  color: theme.palette.secondary.light,
                  fontWeight: "bold",
                  mx: 1,
                }}
              >
                apply
              </Typography>
            </Grid>
            <Grid item>
              <Typography noWrap>the specified</Typography>
            </Grid>
            <Chip
              label={ruleDto?.template?.modificationOperation}
              size="small"
              sx={{
                backgroundColor: theme.palette.secondary.main,
                color: "white",
                mx: 1,
              }}
            />
            <Grid item>
              <Typography noWrap>to the</Typography>
            </Grid>
            <Typography
              noWrap
              component="span"
              sx={{
                color: theme.palette.secondary.main,
                fontWeight: "bold",
                ml: 1,
              }}
            >
              {ruleDto?.template?.modificationField}
            </Typography>
            .
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RuleTemplateCard;
