import React  from "react";
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import {theme} from "../../global/styles/theme";
import ReactJson from 'react-json-view'
import {AppliedRulesDto} from "../../../Api/SocketRulesEngineApi.generated";

const useStyles = makeStyles({
    previewResultContainer: {
        margin: "5px",
        padding: "25px 25px 25px 25px",
    },
});

const PreviewResult = ({testResults}: any)=>{
    
    console.log(testResults)
    
    const classes = useStyles();
    return (
         <Paper elevation={5} className={classes.previewResultContainer}>
            <Grid container>
                <Grid xs={12}>
                    <Typography variant="h6" sx={{ color: theme.palette.info.main }}>
                        Results
                    </Typography>
                </Grid>
                {testResults?.appliedRules?.map((rule : AppliedRulesDto , index : number)=> {
                   return(
                       <Grid xs={12} key={index} container my={2} ml={2}  >
                           <Grid xs={12} mb={1}>
                               <Typography variant="subtitle1">Applied Rule Details</Typography>
                           </Grid>
                            <Grid item xs={2} ml={4}>
                                <Typography
                                    variant="body2"
                                    sx={{ alignSelf: "center" ,  color: theme.palette.info.light,}}
                                >
                                    Rule group
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography
                                    variant="body2" sx={{ color: theme.palette.common.black,}}>
                                    {rule?.ruleGroupName}
                                </Typography>
                            </Grid>
                           <Grid item xs={2} ml={4} >
                               <Typography
                                   variant="body2"
                                   sx={{ alignSelf: "center" ,  color: theme.palette.info.light,}}
                               >
                                   Applied rule
                               </Typography>
                           </Grid>
                           <Grid item xs={9}>
                               <Typography
                                   variant="body2" sx={{ color: theme.palette.common.black,}}>
                                   {"Apply " + rule?.value + " " + rule?.modification + " to " + rule?.modificationField}  
                               </Typography>
                           </Grid>
                       </Grid>
                   
                   ) 
                })}
                {testResults?.modifiedData &&
                    <Grid container ml={2}>
                    <Grid xs={12} >
                        <Typography variant="subtitle1">
                            API Results
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{height:350, overflowY:"auto", border: 1, borderRadius: 1, borderColor: '#C6C9CD'}}>
                         <ReactJson src={testResults?.modifiedData} enableClipboard={false} displayDataTypes={false} />
                    </Grid>
                    </Grid>
                }   
            </Grid>
        </Paper>
    )
}

export default PreviewResult