import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import {
  RuleGroupAssignmentDto,
  RulesApi,
  UpdateRuleGroupDto,
  RuleGroupAssignmentConditionDto,
  RuleAssignmentConditionDto,
} from "../../../../Api/SocketRulesEngineApi.generated";
import { useUserContext } from "../../../../context/providers/UserProvider";
import { theme } from "../../../global/styles/theme";
import AssignmentBasedRulesList from "../../rules/AssignmentBasedRulesList";
import EditAssignmentTemplate from "./assignments/EditAssignmentTemplate";
import { v4 as uuidv4 } from "uuid";
import { AssignmentRowDtoWithKey } from "../../../../models/AssignmentRowDtoWithKey";
import RouteLeavingGuard from "../dialogs/RouteLeavingGuard";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  ruleDefaultContainer: {
    margin: "5px",
    padding: "30px 30px 30px 30px",
    minWidth: "1190px",
  },
  chip: {
    display: "inline-flex",
  },
});

const cloneRuleGroups = (ruleGroups: RuleGroupAssignmentDto[]) => {
  const clone = ruleGroups?.map(
    (r: RuleGroupAssignmentDto) =>
      new AssignmentRowDtoWithKey({
        modificationValue: r.modificationValue,
        key: uuidv4(),
        modificationOperationId: r.modificationOperationId,
        conditions:
          r.conditions?.map(
            (c) =>
              new RuleAssignmentConditionDto({
                conditionFieldId: c.conditionFieldId ?? 0,
                fieldName: c.conditionField ?? "",
                conditionOperationId: c.conditionOperationId ?? 0,
                conditionValues:
                  c.conditionValues != null ? [...c.conditionValues] : [],
              })
          ) ?? [],
      })
  );
  return clone;
};

const RuleAssignmentCard = ({ rule, setRuleDto }: any) => {
  const classes = useStyles();

  const history = useHistory();
  const rulesApi = new RulesApi();
  const { token } = useUserContext();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //TODO: Refactor states using React reducer
  const [editValues, setEditValues] = useState(
    cloneRuleGroups(rule?.ruleAssignments)
  );

  const [defaultEditValue, setDefaultEditValue] = useState<
    AssignmentRowDtoWithKey | undefined
  >(undefined);

  const constructUpdateRuleGroupDto = () => {
    return new UpdateRuleGroupDto({
      id: rule.id,
      ruleAssignments: editValues,
      defaultRuleAssignment: defaultEditValue,
    });
  };

  const handleEditCancel = () => {
    setEditValues(cloneRuleGroups(rule?.ruleAssignments));

    setDefaultEditValue(cloneRuleGroups([rule?.defaultAssignment])[0]);

    setIsEditing(!isEditing);
  };

  const updateDefaultEditValue = (value: string) => {
    const clone = new AssignmentRowDtoWithKey({
      modificationOperationId: defaultEditValue?.modificationOperationId,
      modificationValue: value,
      conditions: [],
      key: "default",
    });
    setDefaultEditValue(clone);
  };

  const updateEditValues = (values: AssignmentRowDtoWithKey[]) => {
    setEditValues([...values]);
  };

  const renderButtons = () => {
    if (isEditing) {
      return (
        <Grid item>
          <Button
            variant="text"
            sx={{ color: "info.main", marginRight: 2 }}
            onClick={handleEditCancel}
          >
            <Typography sx={{ fontSize: "10pt" }}>Cancel</Typography>
          </Button>
          <Button
            variant="contained"
            onClick={() => onEditClick()}
            color={isEditing ? "secondary" : "primary"}
            sx={{
              color: "white",
            }}
          >
            Save
          </Button>
        </Grid>
      );
    }
    return (
      <Button
        variant="contained"
        onClick={() => onEditClick()}
        color={isEditing ? "secondary" : "primary"}
        sx={{
          color: "white",
        }}
      >
        Edit
      </Button>
    );
  };
  const onEditClick = async (isRemoving?: any) => {
    if (!isRemoving) {
      setIsEditing(!isEditing);
    }
    if (isEditing) {
      if (!isRemoving) {
        setIsLoading(true);
      }
      rulesApi.token = token;
      await rulesApi.transformOptions({});
      rulesApi
        .updateRuleGroup(constructUpdateRuleGroupDto())
        .then((ruleGroup) => {
          setRuleDto(ruleGroup);
          setIsLoading(false);
          if (!isRemoving) {
            setIsEditing(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      setIsEditing(!isEditing);
    }
  };

  return (
    <Paper elevation={5} className={classes.ruleDefaultContainer}>
      <RouteLeavingGuard when={isEditing} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={4} direction="column">
        <Grid item container justifyContent="space-between" xs={12}>
          <Grid item>
            <Typography variant="h6" sx={{ color: theme.palette.info.main }}>
              Rule Assignments
            </Typography>
          </Grid>
          <Grid item>{renderButtons()}</Grid>
        </Grid>
        <Grid item container xs={12}>
          {isEditing ? (
            <EditAssignmentTemplate
              rule={rule}
              editValues={editValues}
              setEditValues={updateEditValues}
              updateDefaultEditValue={updateDefaultEditValue}
              onEditClick={onEditClick}
            />
          ) : (
            <AssignmentBasedRulesList rule={rule} />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RuleAssignmentCard;
