import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { IconButton } from "@mui/material";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { ApiLogDto } from "../../../Api/SocketRulesEngineApi.generated";
import Typography from "@mui/material/Typography";

import { DateTime } from "luxon";
import { theme } from "../../global/styles/theme";

const useStyles = makeStyles({
  detailLabel: {
    alignSelf: "start",
    justifySelf: "flex-start",
    color: theme.palette.info.light,
  },
  detailText: {
    color: theme.palette.common.black,
  },
  moreText: {
    color: "#23B1B1",
    cursor: "pointer",
  },
  snapshotDivider: {
    paddingTop: 20,
    paddingBottom: 10,
    alignItems: "start",
    "&:before": {
      width: 0,
    },
  },
});

const RuleDetailPanel = ({
  rule,
  closeRule,
}: {
  rule: ApiLogDto | null;
  closeRule: any;
}) => {
  const classes = useStyles();

  const [showAll, setShowAll] = useState<boolean>(false);
  const [conditionCount, setConditionCount] = useState<number>(0);

  useEffect(() => {
    setShowAll(false);
    setConditionCount(rule?.ruleDetails?.conditionValues?.length ?? 0);
  }, [rule]);

  let conditionText = null;

  if (rule != null) {
    if (rule.ruleDetails?.isDefault) {
      conditionText = (
        <Typography
          className={classes.detailText}
          variant="body2"
          sx={{ mr: 4 }}
        >
          {`If no other rules apply, then apply a ${rule.ruleDetails?.modificationLogString}.`}
        </Typography>
      );
    } else if (showAll || conditionCount <= 3) {
      conditionText = (
        <Typography
          className={classes.detailText}
          variant="body2"
          sx={{ mr: 4 }}
        >
          {`Apply a ${rule.ruleDetails?.modificationLogString} when ${rule.ruleDetails?.conditionField} ${rule.ruleDetails?.conditionOperation} `}
          {`${rule.ruleDetails?.conditionValues
            ?.slice(0, conditionCount - 1)
            .join(", ")}`}
          {conditionCount > 1 ? ", or " : ""}
          {`${rule.ruleDetails?.conditionValues?.slice(-1)[0]}.`}
        </Typography>
      );
    } else {
      conditionText = (
        <Typography
          className={classes.detailText}
          variant="body2"
          sx={{ mr: 4 }}
        >
          {`Apply a ${rule.ruleDetails?.modificationLogString} when ${rule.ruleDetails?.conditionField} ${rule.ruleDetails?.conditionOperation} `}
          {`${rule.ruleDetails?.conditionValues?.slice(0, 3).join(", ")}, ...`}
          {conditionCount > 3 ? (
            <Typography
              component="span"
              className={classes.moreText}
              variant="body2"
              onClick={() => setShowAll(true)}
            >
              {`More`}
            </Typography>
          ) : null}
          {"."}
        </Typography>
      );
    }
  }

  return rule !== null ? (
    <Grid container spacing={2}>
      <Grid
        item
        container
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        alignContent="stretch"
        wrap="wrap"
      >
        <Typography variant="h5" color="initial">
          Applied Rule Details
        </Typography>
        <IconButton aria-label="delete" onClick={() => closeRule(null)}>
          <CloseIcon
            fontSize="small"
            sx={{
              color: "info.light",
            }}
          />
        </IconButton>
      </Grid>

      <Grid item container rowSpacing={4} columnSpacing={2} sx={{ mt: 1 }}>
        <Grid item xs={4} sx={{ display: "flex" }}>
          <Typography
            className={classes.detailLabel}
            variant="body2"
            sx={{ alignSelf: "center" }}
          >
            Rule group
          </Typography>
        </Grid>
        <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
          <Typography className={classes.detailText} variant="body2" sx={{}}>
            {rule.ruleDetails?.ruleGroupName}
          </Typography>
          <IconButton
            sx={{ ml: 2 }}
            component="a"
            href={`rule/${rule.ruleDetails?.url}`}
          >
            <OpenInNewIcon />
          </IconButton>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.detailLabel} variant="body2">
            Response ID
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.detailText} variant="body2">
            {rule.displayId}
          </Typography>
        </Grid>
      </Grid>

      <Divider textAlign="left" className={classes.snapshotDivider}>
        <Typography variant="h6">Rule Snapshot</Typography>
      </Divider>

      <Grid item container rowSpacing={4} columnSpacing={2}>
        <Grid item xs={4}>
          <Typography className={classes.detailLabel} variant="body2">
            Applied rule
          </Typography>
        </Grid>
        <Grid item xs={8}>
          {conditionText}
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.detailLabel} variant="body2">
            Rule status
          </Typography>
        </Grid>
        <Grid item xs={8}>
          {rule.ruleDetails?.isActive ? (
            <Chip
              size="small"
              sx={{
                px: 1,
                backgroundColor: theme.palette.secondary.main,
                color: "white",
              }}
              label="Active"
            />
          ) : (
            <Chip
              size="small"
              sx={{
                px: 1,
                backgroundColor: theme.palette.primary.light,
                color: "white",
              }}
              label="Inactive"
            />
          )}
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.detailLabel} variant="body2">
            Active timeframe
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography
            component="span"
            className={classes.detailText}
            variant="body2"
          >
            {rule.ruleDetails?.activeFrom?.toLocaleString(
              DateTime.DATETIME_SHORT
            )}
          </Typography>
          <Typography
            component="span"
            className={classes.detailLabel}
            variant="body2"
          >
            {" "}
            to{" "}
          </Typography>
          <Typography
            component="span"
            className={classes.detailText}
            variant="body2"
          >
            {rule.ruleDetails?.activeTo?.toLocaleString(
              DateTime.DATETIME_SHORT
            ) ?? "Present"}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.detailLabel} variant="body2">
            Rule set by
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.detailText} variant="body2">
            {rule.ruleDetails?.updatedBy}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};

export default RuleDetailPanel;
