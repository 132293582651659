import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      // Jade (Green)
      main: "#274a4a",
    },
    secondary: {
      // Ember (Orange)
      main: "#E37A41",
    },
    info: {
      // Cool Grey 6
      main: "#767b83",
    },
    background: { default: "#274a4a" },
  },
  spacing: 5,
  typography: {
    fontFamily: "Poppins",
    h4: {
      fontWeight: 300,
    },
    subtitle: {
      fontWeight: 300,
    },
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "5em",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          // boxShadow: "none",
          // boxShadow: "2px 2px 8px #b0b0b6",
          boxShadow:
            "0px 3px 3px -2px rgb(195 222 221 / 20%), 0px 3px 4px 0px rgb(195 222 221 / 15%), 0px 1px 8px 0px rgb(0 0 0 / 20%)",
        },
      },
    },
    MuiCardContent: {
      root: {
        padding: 0,
        "&:last-child": {
          paddingBottom: 0,
        },
      },
    },
  },
});
