import {
  Grid,
  Paper,
  Typography,
  Backdrop,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Button,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from "react";
import {
  Formik,
  Form,
  Field,
  FastField,
  FieldArray,
  FormikProps,
} from "formik";
import {
  ClientApi,
  FieldDto,
  FieldTypeUsageDto,
} from "../../../Api/SocketRulesEngineApi.generated";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ToastAlert from "../../global/ToastAlert";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Settings = ({ redirectPage }: any) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();

  const [fields, setFields] = useState<FieldDto[]>([]);
  const [fieldTypes, setFieldTypes] = useState<string[]>([]);
  const [filteredTypes, setFilteredTypes] = useState<string[]>([]);
  const [filterText, setFilterText] = useState<string>("");
  const [typeUsage, setTypeUsage] = useState<FieldTypeUsageDto[]>([]);
  const [openToast, setToast] = useState<boolean>(false);

  useEffect(() => {
    const clientApi = new ClientApi();
    const getProcessorFields = async () => {
      clientApi.token = token;
      let fields = await clientApi.getClientFields();
      return fields;
    };

    const getDataTypeUsage = async () => {
      clientApi.token = token;
      let usage = await clientApi.getFieldTypeUsage();
      return usage;
    };
    const fetchData = async () => {
      const fields = await getProcessorFields();
      const fieldTypeUsage = await getDataTypeUsage();
      setTypeUsage(fieldTypeUsage);
      updateDataFields(fields);
    };
    fetchData();
  }, [token]);

  const updateDataFields = (data: FieldDto[]) => {
    const types = [...new Set(data.map((d) => d.fieldType ?? ""))];
    setFieldTypes(types);
    setFields(data);
  };

  const saveChanges = async (updatedFields: FieldDto[]) => {
    const clientApi = new ClientApi();
    clientApi.token = token;

    setLoading(true);
    await clientApi
      .updateRuleProcessorFields(updatedFields)
      .then((result) => {
        updateDataFields(result);
        setLoading(false);
        setToast(true);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleDataTypeChange = (
    event: SelectChangeEvent<typeof filteredTypes>
  ) => {
    const {
      target: { value },
    } = event;
    setFilteredTypes(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleFilterTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilterText(event.target.value);
  };

  const filterRow = (fieldRow: FieldDto) => {
    // Filter based on type dropdown
    if (
      filteredTypes.length > 0 &&
      filteredTypes.indexOf(fieldRow.fieldType ?? "") === -1
    ) {
      return true;
    }

    // Filter based on text entry
    const filter = filterText.toLowerCase();
    if (
      fieldRow.displayName?.toLowerCase().indexOf(filter) === -1 &&
      fieldRow.fieldName?.toLowerCase().indexOf(filter) === -1
    ) {
      return true;
    }

    return false;
  };

  const enableCheckbox = (type: any, useIn: string): boolean => {
    let found = typeUsage.find((t) => t.fieldType === type);
    if (found !== undefined) {
      return found[useIn as keyof FieldTypeUsageDto] as boolean;
    }
    return false;
  };

  return (
    <Grid item container xs={12} width="100%">
      <ToastAlert
        open={openToast}
        setToast={setToast}
        errorMsg={"Setting Changes have been Saved"}
      />
      <Paper
        elevation={10}
        sx={{
          padding: "40px 40px 30px 40px",
          width: "100%",
          minHeight: "calc(100vh - 120px)",
        }}
      >
        <Grid item container spacing={5}>
          <Grid item xs={12}>
            <Typography variant="h4">Settings</Typography>
          </Grid>
          <Grid item container xs={12} spacing={4}>
            <Grid item xs={4}>
              <Tooltip
                title="Filter available fields for the rule engine based on the Field or Display name."
                placement="top"
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Filter"
                  size="small"
                  value={filterText}
                  onChange={handleFilterTextChange}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Tooltip
                title="Filter available fields for the rule engine based on the data type of the Field."
                placement="top"
              >
                <FormControl fullWidth size="small">
                  <InputLabel id="data-type-select-label">Data Type</InputLabel>
                  <Select
                    labelId="data-type-select-label"
                    id="data-type-select"
                    multiple
                    value={filteredTypes}
                    onChange={handleDataTypeChange}
                    input={<OutlinedInput label="Data Type" />}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>Placeholder</em>;
                      }
                      return selected.join(", ");
                    }}
                  >
                    {fieldTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        <Checkbox checked={filteredTypes.indexOf(type) > -1} />
                        <ListItemText primary={type} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={{ fields: fields }}
              enableReinitialize
              validateOnChange={false}
              onSubmit={async (values, actions) => {
                const updatedFields = values.fields.filter((field, i) => {
                  return (
                    field.displayName !== fields[i].displayName ||
                    field.allowedAsCondition !== fields[i].allowedAsCondition ||
                    field.allowedAsModification !==
                      fields[i].allowedAsModification
                  );
                });

                saveChanges(updatedFields);
              }}
            >
              {(formik) => {
                const { handleChange, values } = formik;

                return (
                  <Form>
                    <Grid container rowSpacing={4}>
                      <Grid item xs={12}>
                        <TableContainer
                          component={Paper}
                          sx={{ maxHeight: 600 }}
                        >
                          <Table
                            stickyHeader
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell sx={{ maxWidth: 300 }}>
                                  Field
                                </StyledTableCell>
                                <StyledTableCell sx={{ width: 400 }}>
                                  Display
                                </StyledTableCell>
                                <StyledTableCell>Data Type</StyledTableCell>
                                <StyledTableCell>
                                  Use in Conditions
                                </StyledTableCell>
                                <StyledTableCell>
                                  Use in Modifications
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <FieldArray
                                name="fields"
                                render={(arrayHelpers: any) =>
                                  values.fields.map((row, index) => {
                                    if (filterRow(row)) {
                                      return null;
                                    } else {
                                      return (
                                        <TableRow
                                          key={index}
                                          sx={{
                                            "&:last-child td, &:last-child th":
                                              {
                                                border: 0,
                                              },
                                          }}
                                        >
                                          <TableCell component="th" scope="row">
                                            <Tooltip title={row.fieldName}>
                                              <span
                                                style={{
                                                  textOverflow: "ellipsis",
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                  direction: "rtl",
                                                  display: "flow",
                                                  maxWidth: 400,
                                                }}
                                              >
                                                {row.fieldName}
                                              </span>
                                            </Tooltip>
                                          </TableCell>
                                          <TableCell>
                                            <TextField
                                              name={`fields.${index}.displayName`}
                                              value={row.displayName}
                                              variant="outlined"
                                              size="small"
                                              onChange={handleChange}
                                              sx={{ width: "100%" }}
                                            ></TextField>
                                          </TableCell>
                                          <TableCell>{row.fieldType}</TableCell>
                                          <TableCell>
                                            {!enableCheckbox(
                                              row?.fieldType,
                                              "forUseInConditions"
                                            ) ? (
                                              <Tooltip
                                                title="There are no operations available for this Data Type currently"
                                                arrow={true}
                                              >
                                                <div>
                                                  <FastField
                                                    type="checkbox"
                                                    name={`fields.${index}.allowedAsCondition`}
                                                    value={
                                                      row.allowedAsCondition
                                                    }
                                                    key={row.fieldId}
                                                    as={FormControlLabel}
                                                    control={<Checkbox />}
                                                    checked={
                                                      row.allowedAsCondition
                                                    }
                                                    label="Enabled"
                                                    disabled={
                                                      !enableCheckbox(
                                                        row?.fieldType,
                                                        "forUseInConditions"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </Tooltip>
                                            ) : (
                                              <FastField
                                                type="checkbox"
                                                name={`fields.${index}.allowedAsCondition`}
                                                value={row.allowedAsCondition}
                                                key={row.fieldId}
                                                as={FormControlLabel}
                                                control={<Checkbox />}
                                                checked={row.allowedAsCondition}
                                                label="Enabled"
                                                disabled={
                                                  !enableCheckbox(
                                                    row?.fieldType,
                                                    "forUseInConditions"
                                                  )
                                                }
                                              />
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {!enableCheckbox(
                                              row?.fieldType,
                                              "forUseInModifications"
                                            ) ? (
                                              <Tooltip
                                                title="There are no operations available for this Data Type currently"
                                                arrow={true}
                                              >
                                                <div>
                                                  <FastField
                                                    type="checkbox"
                                                    name={`fields.${index}.allowedAsModification`}
                                                    value={
                                                      row.allowedAsModification
                                                    }
                                                    key={row.fieldId}
                                                    as={FormControlLabel}
                                                    control={<Checkbox />}
                                                    checked={
                                                      row.allowedAsModification
                                                    }
                                                    label="Enabled"
                                                    disabled={
                                                      !enableCheckbox(
                                                        row?.fieldType,
                                                        "forUseInModifications"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </Tooltip>
                                            ) : (
                                              <FastField
                                                type="checkbox"
                                                name={`fields.${index}.allowedAsModification`}
                                                value={
                                                  row.allowedAsModification
                                                }
                                                key={row.fieldId}
                                                as={FormControlLabel}
                                                control={<Checkbox />}
                                                checked={
                                                  row.allowedAsModification
                                                }
                                                label="Enabled"
                                                disabled={
                                                  !enableCheckbox(
                                                    row?.fieldType,
                                                    "forUseInModifications"
                                                  )
                                                }
                                              />
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  })
                                }
                              />
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        direction="row"
                        justifyContent="right"
                        alignItems="center"
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          sx={{
                            color: "white",
                          }}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>

            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={isLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Settings;
