import {
  Avatar,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  OperationDto,
  RuleBuilderFieldDto,
} from "../../../../Api/SocketRulesEngineApi.generated";
import { useState } from "react";
import { INewRuleForm } from "./NewRuleCard";
import { FormikProps } from "formik";

const NewCondition = ({
  options,
  formik,
  index,
  removeCondition,
}: {
  options: RuleBuilderFieldDto[];
  formik: FormikProps<INewRuleForm>;
  index: number;
  removeCondition: any;
}) => {
  const [operations, setOperations] = useState<OperationDto[]>([]);

  const handleConditionChange = (event: SelectChangeEvent) => {
    const conditionId = event.target.value;

    console.log("condition", conditionId);

    formik.setFieldValue(`conditions[${index}].conditionId`, conditionId);

    const selectedOption = options.find(
      (c) => conditionId == c.fieldId?.toString()
    );

    const conditionOperations =
      selectedOption?.availableOperations?.filter(
        (o) => o.allowedAsCondition
      ) ?? [];

    setOperations(conditionOperations);

    if (conditionOperations.length === 1) {
      formik.setFieldValue(
        `conditions[${index}].conditionOperation`,
        conditionOperations[0].operationId?.toString(),
        true
      );

      setTimeout(() => formik.setFieldTouched("modificationOperation", true));
    } else {
      formik.setFieldValue(`conditions[${index}].conditionOperation`, "", true);
    }
  };

  const handleOperationChange = (event: SelectChangeEvent) => {
    formik.setFieldValue(
      `conditions[${index}].conditionOperation`,
      event.target.value,
      true
    );
  };

  return (
    <Grid container>
      <Grid
        item
        xs={2}
        sx={{ alignSelf: "center" }}
        container
        justifyContent={"space-between"}
      >
        <Avatar
          sx={{
            backgroundColor: "primary.main",
          }}
        >
          <Typography color="white" fontSize="small">
            {index > 0 ? "And" : "If"}
          </Typography>
        </Avatar>
        {index > 0 && (
          <IconButton
            aria-label="delete"
            onClick={() => removeCondition(formik, index)}
          >
            <CloseIcon
              fontSize="small"
              sx={{
                color: "info.light",
              }}
            />
          </IconButton>
        )}
      </Grid>
      <Grid item xs={5} sx={{ px: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="condition-select-label">Field</InputLabel>
          <Select
            labelId="condition-select-label"
            id="condition-select"
            value={formik.values.conditions[index].conditionId}
            label="Field"
            onChange={handleConditionChange}
          >
            {options.map((o) => (
              <MenuItem
                key={`field_${o.fieldId}`}
                value={o.fieldId?.toString() ?? ""}
              >
                {o.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={5} sx={{ px: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="condition-operation-select-label">
            operation
          </InputLabel>
          <Select
            labelId="condition-operation-select-label"
            id="condition-operation-select"
            value={formik.values.conditions[index].conditionOperation}
            label="operation"
            onChange={handleOperationChange}
            disabled={operations.length < 1}
          >
            {operations.map((o) => (
              <MenuItem
                key={`operation_${o.operationId}`}
                value={o.operationId?.toString() ?? ""}
              >
                {o.operationDisplay}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default NewCondition;
