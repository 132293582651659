import React, { useEffect } from "react";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import { Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { DateTime } from "luxon";
import { theme } from "../../global/styles/theme";
import {
  ConditionOperationType,
  ModificationOperationType,
} from "../../../utilities/OperationTypes";
import {
  RuleAssignmentDto,
  RuleGroupAssignmentConditionDto,
  RuleGroupAssignmentDto,
  RuleGroupDisplayDto,
} from "../../../Api/SocketRulesEngineApi.generated";

const ConditionChip = ({ label }: { label: string }) => {
  return (
    <Grid item key={label}>
      <Chip label={label} size="medium" variant="outlined" />
    </Grid>
  );
};

const ConditionFieldChip = ({
  condition,
}: {
  condition: RuleGroupAssignmentConditionDto;
}) => {
  return (
    <Chip
      label={condition.conditionField}
      size="small"
      sx={{
        backgroundColor: theme.palette.primary.light,
        color: "white",
        mx: 1,
      }}
    />
  );
};

const AssignmentBasedRulesList = ({ rule }: { rule: RuleGroupDisplayDto }) => {
  let location = useLocation();
  const launchDarklyFeatureFlag = useFlags();

  const anyConditions = rule?.ruleAssignments?.filter((a) =>
    a.conditions?.filter((c) => (c.conditionValues?.length ?? 0) > 0)
  );

  const renderCondition = (
    condition: RuleGroupAssignmentConditionDto,
    index: number
  ) => {
    if (condition?.conditionValues?.length == 0) {
      return (
        <Grid item container alignItems="center">
          <ConditionFieldChip condition={condition} />
          <Typography sx={{}}>condition not configured</Typography>
        </Grid>
      );
    }

    switch (condition?.conditionOperationId) {
      case ConditionOperationType.NumberIsInRange:
        return condition?.conditionValues?.map((c: string, index: number) => {
          const range = JSON.parse(c);
          return (
            <Grid item container key={c} alignItems={"center"}>
              <ConditionFieldChip condition={condition} />
              {range.min && range.max && (
                <Typography>is equal or between </Typography>
              )}
              {range.min && !range.max && (
                <Typography>is equal or greater than </Typography>
              )}
              {!range.min && range.max && (
                <Typography>is equal or less than </Typography>
              )}
              {range.min && (
                <Chip
                  sx={{ mx: 1 }}
                  label={range.min}
                  size="medium"
                  variant="outlined"
                />
              )}
              {range.min && range.max && <Typography> and </Typography>}
              {range.max && (
                <Chip
                  sx={{ mx: 1 }}
                  label={range.max}
                  size="medium"
                  variant="outlined"
                />
              )}
            </Grid>
          );
        });
      case ConditionOperationType.DateIsInRange:
        return condition?.conditionValues?.map((c: string, index: number) => {
          const range = JSON.parse(c);
          const startDate =
            range.min && DateTime.fromISO(range.min).toFormat("MM/dd/yyyy");
          const endDate =
            range.max && DateTime.fromISO(range.max).toFormat("MM/dd/yyyy");
          return (
            <Grid item container key={c} alignItems={"center"}>
              <ConditionFieldChip condition={condition} />
              {range.min && range.max && (
                <Typography>on or between </Typography>
              )}
              {range.min && !range.max && <Typography>on or after </Typography>}
              {!range.min && range.max && (
                <Typography>on or before </Typography>
              )}
              {range.min && (
                <Chip
                  sx={{ mx: 1 }}
                  label={startDate}
                  size="medium"
                  variant="outlined"
                />
              )}
              {range.min && range.max && <Typography> and </Typography>}
              {range.max && (
                <Chip
                  sx={{ mx: 1 }}
                  label={endDate}
                  size="medium"
                  variant="outlined"
                />
              )}
            </Grid>
          );
        });
      case ConditionOperationType.StringStartsWith:
      case ConditionOperationType.StringArrayStartsWith:
        return (
          <Grid item container alignItems={"center"} flexWrap="nowrap">
            <ConditionFieldChip condition={condition} />
            <Grid item>
              <Typography sx={{ mr: 2 }} noWrap>
                starts with
              </Typography>
            </Grid>
            <Grid item container>
              {condition?.conditionValues?.map((c: string, index: number) => {
                return <ConditionChip label={c} key={uuidv4()} />;
              })}
            </Grid>
          </Grid>
        );
      case ConditionOperationType.StringEquals:
      case ConditionOperationType.StringArrayEquals:
        return (
          <Grid item container alignItems={"center"} flexWrap={"nowrap"}>
            {/* <Typography>{condition.conditionField}</Typography> */}
            <ConditionFieldChip condition={condition} />
            <Typography sx={{ mr: 2 }}>matches </Typography>
            <Grid item container>
              {condition?.conditionValues?.map((c: string, index: number) => {
                return <ConditionChip label={c} key={uuidv4()} />;
              })}
            </Grid>
          </Grid>
        );
      case ConditionOperationType.StringContains:
      case ConditionOperationType.StringArrayContains:
        return (
          <Grid item container alignItems={"center"} flexWrap={"nowrap"}>
            <ConditionFieldChip condition={condition} />
            <Typography sx={{ mr: 2 }}>contains </Typography>
            <Grid item container>
              {condition?.conditionValues?.map(
                (condition: string, index: number) => {
                  return <ConditionChip label={condition} key={uuidv4()} />;
                }
              )}
            </Grid>
          </Grid>
        );
      case ConditionOperationType.Boolean:
        return (
          <Grid item container alignItems={"center"} flexWrap={"nowrap"}>
            <ConditionFieldChip condition={condition} />
            <Typography sx={{ mr: 2 }}>is </Typography>
            <Grid item container>
              {condition?.conditionValues?.map(
                (condition: string, index: number) => {
                  return <ConditionChip label={condition} key={uuidv4()} />;
                }
              )}
            </Grid>
          </Grid>
        );
      default:
        return condition?.conditionValues?.map(
          (condition: string, index: number) => {
            return <ConditionChip label={condition} />;
          }
        );
    }
  };

  const renderModification = (r: RuleGroupAssignmentDto) => {
    switch (r.modificationOperationId) {
      case ModificationOperationType.MarkupPercent:
        return (
          <Typography color="secondary.main" sx={{ fontSize: "small" }}>
            {r?.modificationValue}%
          </Typography>
        );
      case ModificationOperationType.MarkupFlat:
        return (
          <Typography color="secondary.main" sx={{ fontSize: "small" }}>
            ${r?.modificationValue}
          </Typography>
        );
      case ModificationOperationType.AddMarkup:
        let markup: any = {};
        try {
          markup = JSON.parse(r?.modificationValue ?? "{}");
        } catch (e) {}
        return (
          <Typography color="secondary.main" sx={{ fontSize: "small" }}>
            {markup?.type === "percent" && `${markup.amount} %`}
            {markup?.type === "flat" &&
              `$ ${Number(markup?.amount).toFixed(2)}`}
            {markup?.type == null && `Not set`}
          </Typography>
        );
      default:
        return (
          <Typography color="secondary.main" sx={{ fontSize: "small" }}>
            {r?.modificationValue}
          </Typography>
        );
    }
  };

  return (
    <Grid item container>
      {!anyConditions &&
      location.pathname == "/rules" &&
      launchDarklyFeatureFlag["sru-empty-rule-conditions-message"] ? (
        <Grid item container>
          <Typography>
            Assignments for this rule have not been added yet.
          </Typography>
        </Grid>
      ) : (
        <React.Fragment>
          <Grid item container className="header" direction="row">
            <Grid item container direction="column" md="auto">
              <Grid item mb={8} sx={{ width: 25 }} />
            </Grid>
            <Grid item container direction="column" md={8} ml={10}>
              <Grid item mb={2} ml={1}>
                <Typography color="primary.main">Conditions</Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column" md="auto" alignSelf={"end"}>
              <Grid item mb={3}>
                <Typography color="secondary.main">
                  {rule?.template?.modificationOperation}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {rule?.defaultAssignment && (
            <React.Fragment>
              <Grid item container alignItems="baseline">
                <Grid item sx={{ width: 25 }}></Grid>
                <Grid item container ml={10} xs={8} sm={8} md={8} lg={8}>
                  <Chip
                    label="Default"
                    size="small"
                    sx={{
                      backgroundColor: theme.palette.primary.light,
                      color: "white",
                      mx: 1,
                    }}
                  />
                  {/* {"Default to use when no other conditions match"} */}
                </Grid>
                <Grid item container md="auto">
                  {renderModification(rule.defaultAssignment)}
                </Grid>
              </Grid>
              <Divider sx={{ backgroundColor: "info.main", marginTop: 2 }} />
            </React.Fragment>
          )}
          {rule?.ruleAssignments?.map(
            (r: RuleGroupAssignmentDto, index: number) => {
              return (
                <React.Fragment key={uuidv4()}>
                  <Grid item container mt={2} alignItems="baseline">
                    <Grid item container md="auto">
                      <Grid item>
                        <Avatar
                          sx={{
                            width: 25,
                            height: 25,
                            backgroundColor: "primary.main",
                          }}
                        >
                          <Typography color="white" fontSize="small">
                            {index + 1}
                          </Typography>
                        </Avatar>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      ml={10}
                      xs={8}
                      sm={8}
                      md={8}
                      lg={8}
                      direction={"column"}
                      sx={{ paddingRight: "15px" }}
                      className="condition-display-container"
                    >
                      {r.conditions?.map((c, ci) => {
                        return (
                          <Grid key={uuidv4()} sx={{ mt: ci > 0 ? 2 : 0 }}>
                            {renderCondition(c, ci)}
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Grid item container md="auto" alignSelf={"center"}>
                      {renderModification(r)}
                    </Grid>
                  </Grid>
                  <Divider
                    sx={{ backgroundColor: "info.main", marginTop: 2 }}
                  />
                </React.Fragment>
              );
            }
          )}
        </React.Fragment>
      )}
    </Grid>
  );
};

export default AssignmentBasedRulesList;
