import React, { useState, useEffect } from "react";
import Chip from "@mui/material/Chip";
import { theme } from "../../../../../global/styles/theme";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AssignmentRowDtoWithKey } from "../../../../../../models/AssignmentRowDtoWithKey";
import { MenuItem, Select } from "@mui/material";

const ConditionBooleanAssignment = ({
  rowData,
  addCondition,
  removeCondition,
  index,
  conditionIndex,
  setInputValue,
  removeRow,
}: {
  rowData: AssignmentRowDtoWithKey;
  addCondition: (
    condition: string,
    rowIndex: number,
    conditionIndex: number
  ) => void;
  removeCondition: any;
  index: any;
  conditionIndex: number;
  setInputValue: any;
  removeRow: any;
}) => {
  const [booleanCondition, setBooleanCondition] = useState<string>("");

  const setValue = (val: string) => {
    console.log("set val", val, rowData?.conditions[conditionIndex]);
    setBooleanCondition(val);
    addCondition(val, index, conditionIndex);
  };

  useEffect(() => {
    const val =
      rowData?.conditions[conditionIndex].conditionValues?.[0] ?? "false";
    //setValue(val);
    setBooleanCondition(val);
  }, [rowData]);

  return (
    <Grid item container className="container" alignItems="center" spacing={1}>
      <Chip
        label={rowData.conditions[conditionIndex].fieldName}
        size="small"
        sx={{
          backgroundColor: theme.palette.primary.light,
          color: "white",
          mx: 1,
        }}
      />
      <Typography sx={{ pr: 2 }}>is</Typography>
      <Select
        value={booleanCondition}
        onChange={(event) => setValue(event.target.value)}
        sx={{ width: "120px" }}
      >
        <MenuItem value="true">True</MenuItem>
        <MenuItem value="false">False</MenuItem>
      </Select>
    </Grid>
  );
};

export default ConditionBooleanAssignment;
