import React, { useState } from "react";
import Grid from "@mui/material/Grid";

import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFlags } from "launchdarkly-react-client-sdk";
import { DateTime } from "luxon";
import ConfirmDeleteDialog from "../dialogs/ConfirmDeleteDialog";
import { useOktaAuth } from "@okta/okta-react";
import { RulesApi } from "../../../../Api/SocketRulesEngineApi.generated";

const useStyles = makeStyles({
  ruleTitleContainer: {
    margin: "5px",
    // padding: "10px",
    fontStyle: "normal",
    fontWeight: 300,
    letterSpacing: ".15px",
    fontSize: "24pt",
  },
  ruleTitleHeader: {
    fontSize: "24pt",
  },
  ruleTitleInfoContainer: {
    margin: "5px",
    padding: "10px",
    lineHeight: "35px",
  },
  title: {
    margin: "0 0 0 0",
    fontStyle: "normal",
    fontWeight: 300,
    letterSpacing: ".15px",
    fontSize: "24px",
  },
  description: {
    margin: "0 0 0 0",
    width: "100%",
    pointerEvents: "none",
    fontSize: "12px",
  },
  lastUpdated: {
    textAlign: "right",
    color: "#777B83",
    opacity: ".8",
  },
});

type TitleProps = {
  ruleGroupId: number;
  title: string;
  description: string;
  lastUpdatedBy: string;
  lastUpdatedDate: string;
  startDate: DateTime | null;
  endDate: DateTime | null;
  redirectPage: any;
};
const RuleDetailTitle = ({
  ruleGroupId,
  title,
  description,
  startDate,
  endDate,
  lastUpdatedBy,
  lastUpdatedDate,
  redirectPage,
}: TitleProps) => {
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const rulesApi = new RulesApi();

  const classes = useStyles();
  const launchDarklyFeatureFlag = useFlags();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const onConfirmDelete = async () => {
    rulesApi.token = token;
    await rulesApi.transformOptions({});
    rulesApi
      .deleteRuleGroup(ruleGroupId)
      .then((result) => {
        redirectPage("/rules");
        setConfirmDelete(false);
      })
      .catch((err) => {
        setConfirmDelete(false);
      });

    setConfirmDelete(false);
  };
  return (
    <>
      <Grid item container pb={5}>
        <ConfirmDeleteDialog
          onConfirm={() => onConfirmDelete()}
          onCancel={() => setConfirmDelete(false)}
          visible={confirmDelete}
        />
        <Grid item xs={11}>
          <Typography variant="h4">Rule Details</Typography>
        </Grid>
        <Grid
          item
          xs={1}
          container
          justifyContent="flex-end"
          alignContent="center"
        >
          {launchDarklyFeatureFlag["sru-create-rule-group-button"] && (
            <Grid item xs={6} container justifyContent="flex-end">
              <Tooltip title="Delete this Rule" arrow>
                <Button
                  variant="contained"
                  color="info"
                  sx={{
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    minWidth: "auto",
                  }}
                  onClick={() => setConfirmDelete(true)}
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.ruleTitleContainer}>
        <Grid item container direction="column" lg={8} md={8} sm={8}>
          <Grid item>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item mt={2}>
            <Typography> {description}</Typography>
            {/* future state will be an input to edit description name */}
            {/* <Input value={description} className={classes.description} /> */}
          </Grid>
          {(startDate || endDate) && (
            <Grid item mt={2}>
              <Typography>
                {startDate &&
                  endDate &&
                  `This Rule is only applies between ${startDate?.toLocaleString()} and ${endDate?.toLocaleString()}`}
                {startDate &&
                  !endDate &&
                  `This Rule is only applies after ${startDate?.toLocaleString()}`}
                {!startDate &&
                  endDate &&
                  `This Rule is only applies until ${endDate?.toLocaleString()}`}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          container
          direction="column"
          lg={4}
          md={4}
          sm={4}
          className={classes.lastUpdated}
        >
          <Typography component="div" variant="body2" sx={{ lineHeight: 1.7 }}>
            <Grid item>Last updated by</Grid>
            <Grid item>{lastUpdatedBy}</Grid>
            <Grid item>{lastUpdatedDate}</Grid>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default RuleDetailTitle;
