import {
  IRuleAssignmentDto,
  RuleAssignmentConditionDto,
  RuleAssignmentDto,
} from "../Api/SocketRulesEngineApi.generated";

export interface IAssignmentRowDtoWithKey extends IRuleAssignmentDto {
  key: string;

  //conditionOperationId: number | null | undefined;
  modificationOperationId: number | undefined;
}

export class AssignmentRowDtoWithKey extends RuleAssignmentDto {
  key: string;
  //conditionOperationId: number | null | undefined;
  modificationOperationId: number | undefined;

  constructor(data: IAssignmentRowDtoWithKey) {
    super({
      modificationValue: data.modificationValue,
      conditions: data.conditions,
    });
    this.key = data.key;

    //this.conditionOperationId = data.conditionOperationId;
    this.modificationOperationId = data.modificationOperationId;
  }
}

// export class ConditionDtoWithKey extends RuleAssignmentConditionDto {
//   key: string;

//   constructor(data: )
// }
