import { useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ChecklistRtlRoundedIcon from "@mui/icons-material/ChecklistRtlRounded";
import HistoryIcon from "@mui/icons-material/History";
import SettingsIcon from "@mui/icons-material/Settings";
import PreviewIcon from "@mui/icons-material/Preview";
import { StyledList } from "./StyledNavComponents";
import { useFlags } from "launchdarkly-react-client-sdk";

class NavItem {
  key!: string;
  name!: string;
  path!: string;
  matchPaths!: string[];
  icon!: JSX.Element;
  enabled!: boolean;
}

const SideNavigationList = ({ redirectPage }: any) => {
  const [openList, setOpenList] = useState<boolean>(false);

  const { pathname } = useLocation();
  const launchDarklyFeatureFlag = useFlags();

  const navItems: NavItem[] = [
    {
      key: "rules",
      name: "Rules",
      path: "/rules",
      matchPaths: ["/rules", "/rule/:id"],
      icon: <ChecklistRtlRoundedIcon />,
      enabled: true,
    },
    {
      key: "history",
      name: "History",
      path: "/history",
      matchPaths: ["/history"],
      icon: <HistoryIcon />,
      enabled: launchDarklyFeatureFlag["sru-api-log-history"],
    },

    {
      key: "preview",
      name: "Preview",
      path: "/preview",
      matchPaths: ["/preview"],
      icon: <PreviewIcon />,
      enabled: launchDarklyFeatureFlag["sru-api-preview"],
    },
    {
      key: "settings",
      name: "Settings",
      path: "/settings",
      matchPaths: ["/settings"],
      icon: <SettingsIcon />,
      enabled: launchDarklyFeatureFlag["sru-api-configuration"],
    },
  ];

  return (
    <Grid item container xs={12}>
      <StyledList>
        {navItems
          .filter((nav: NavItem) => nav.enabled)
          .map((nav: NavItem) => {
            return (
              <ListItemButton
                id={`${nav.key}-btn`}
                key={nav.key}
                sx={{
                  minwidth: "250px",
                }}
                selected={!!matchPath(pathname, nav.matchPaths)}
                onClick={() => {
                  setOpenList(false);
                  redirectPage(nav.path);
                }}
              >
                <ListItemIcon
                  sx={{
                    color: "secondary.light",
                    minWidth: "40px",
                  }}
                >
                  {nav.icon}
                </ListItemIcon>
                <ListItemText
                  id={`${nav.key}-btn-text`}
                  primary={nav.name}
                  primaryTypographyProps={{
                    color: "white",
                    fontSize: "14px",
                  }}
                />
              </ListItemButton>
            );
          })}
      </StyledList>
    </Grid>
  );
};

export default SideNavigationList;
