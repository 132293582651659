import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import { Select, MenuItem } from "@mui/material";
import { AssignmentRowDtoWithKey } from "../../../../../../models/AssignmentRowDtoWithKey";

const ModificationMarkupAssignment = ({
  rowData,
  onChange,
}: {
  rowData: AssignmentRowDtoWithKey;
  onChange: any;
}) => {
  let markup = { amount: "", type: "percent" };
  try {
    markup = JSON.parse(
      rowData?.modificationValue ?? '{"amount": "", "type": "percent"}'
    );
  } catch (ex) {}

  const [amount, setAmount] = useState<string | number>(markup.amount);
  const [type, setType] = useState<string>(markup.type);

  useEffect(() => {
    const modification = JSON.stringify({
      amount: amount?.toString(),
      type: type,
    });
    onChange(modification);
  }, [amount, type]);

  return (
    <Grid item>
      <TextField
        id="standard-basic"
        type="number"
        value={amount}
        onChange={(event) => setAmount(event.target.value)}
        sx={{
          width: "100px",
          fieldset: { borderColor: "info.main" },
          mr: 2,
        }}
        InputProps={{
          endAdornment: type === "percent" && (
            <InputAdornment position="end">%</InputAdornment>
          ),
          startAdornment: type === "flat" && (
            <InputAdornment position="start">$</InputAdornment>
          ),
        }}
      />
      <Select
        value={type}
        onChange={(event) => setType(event.target.value)}
        sx={{ width: "160px" }}
      >
        <MenuItem value={"flat"}>Flat Amount</MenuItem>
        <MenuItem value={"percent"}>Percentage</MenuItem>
      </Select>
    </Grid>
  );
};

export default ModificationMarkupAssignment;
