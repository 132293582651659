import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "../src/App";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
require("./index.scss");

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
    /* TODO:  uncomment context when ready to add */
    // context: {},
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });
  ReactDOM.render(
    <BrowserRouter>
      <LDProvider>
        <App />
      </LDProvider>
    </BrowserRouter>,
    document.getElementById("root")
  );
})();
