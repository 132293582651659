import React from "react";
import Grid from "@mui/material/Grid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import { Select, MenuItem } from "@mui/material";
import ModificationNumericAssignment from "./modifications/ModificationNumericAssignment";
import ModificationMarkupAssignment from "./modifications/ModificationMarkupAssignment";

const RowModificationAssignment = ({
  rowData,
  changeRowModificationValue,
  index,
  numOfAssignments,
  addAssignment,
}: any) => {
  // const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   // console.log(event.target.value);
  //   changeRowModificationValue(index, event.target.value);
  // };

  const onChange = (value: string) => {
    changeRowModificationValue(index, value);
  };

  return (
    <Grid item container alignItems="center" ml={5} spacing={3} direction="row">
      {rowData.modificationOperationId === 1 && (
        <ModificationNumericAssignment rowData={rowData} onChange={onChange} />
      )}
      {rowData.modificationOperationId === 10 && (
        <ModificationMarkupAssignment rowData={rowData} onChange={onChange} />
      )}
    </Grid>
  );
};

export default RowModificationAssignment;
