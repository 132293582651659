import React from "react";
import Button from "@mui/material/Button";
import { useOktaAuth } from "@okta/okta-react";
import { Redirect } from "react-router-dom";

const LoginPage = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };

  //checks if user is a
  if (authState?.isAuthenticated) {
    return <Redirect to="/rules" />;
  } else {
    login();
    return null;
  }
};

export default LoginPage;
