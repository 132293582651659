import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Navbar from "../navigation/Navbar";
import Paper from "@mui/material/Paper";
import SideNavigationDrawer from "../navigation/SideNavigationDrawer";

type LayoutProps = {
  theme: any;
  children: JSX.Element | Element[];
  redirectPage: (route: string) => void;
};

const useStyles = makeStyles({
  paper: {
    backgroundColor: "#f2f0f2 !important",
    borderRadius: "10px !important",
    width: "100%",
    minHeight: "90vh",
  },
  grid: {
    padding: "20px 20px 20px 20px",
  },
});

const Layout = ({ theme, children, redirectPage }: LayoutProps) => {
  const classes = useStyles();
  return (
    <Box>
      <Navbar theme={theme} redirectPage={redirectPage} />
      <SideNavigationDrawer theme={theme} redirectPage={redirectPage}>
        <Box sx={{ display: "flex" }}>
          <Paper className={classes.paper}>
            <Grid item container spacing={6} className={classes.grid}>
              {children}
            </Grid>
          </Paper>
        </Box>
      </SideNavigationDrawer>
    </Box>
  );
};

export default Layout;
