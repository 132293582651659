import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import RowModificationAssignment from "./RowModificationAssignment";

import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { v4 as uuidv4 } from "uuid";
import { AssignmentRowDtoWithKey } from "../../../../../models/AssignmentRowDtoWithKey";
import ToastAlert from "../../../../global/ToastAlert";
import {
  ConditionOperationType,
  ModificationOperationType,
} from "../../../../../utilities/OperationTypes";
import ConditionStringEqualsAssignment from "./conditions/ConditionStringEqualsAssignment";
import ConditionNumberInRangeAssignment from "./conditions/ConditionNumberInRangeAssignment";
import ConditionDateInRangeAssignment from "./conditions/ConditionDateInRangeAssignment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  RuleAssignmentConditionDto,
  RuleGroupDisplayDto,
} from "../../../../../Api/SocketRulesEngineApi.generated";
import { Divider } from "@mui/material";
import ConditionBooleanAssignment from "./conditions/ConditionBooleanAssignment";

const ConditionEditTemplate = ({
  rowData,
  rowIndex,
  conditionIndex,
  addCondition,
  removeCondition,
  removeRow,
  setInputValue,
}: {
  rowData: AssignmentRowDtoWithKey;
  rowIndex: number;
  conditionIndex: number;
  addCondition: any;
  removeCondition: (
    condition: string,
    rowIndex: number,
    conditionIndex: number
  ) => string[];
  removeRow: any;
  setInputValue: any;
}) => {
  switch (rowData.conditions[conditionIndex].conditionOperationId) {
    case ConditionOperationType.Boolean:
      return (
        <ConditionBooleanAssignment
          rowData={rowData}
          index={rowIndex}
          conditionIndex={conditionIndex}
          addCondition={addCondition}
          removeCondition={removeCondition}
          removeRow={removeRow}
          setInputValue={setInputValue}
        />
      );
    case ConditionOperationType.DateIsInRange:
      return (
        <ConditionDateInRangeAssignment
          rowData={rowData}
          index={rowIndex}
          conditionIndex={conditionIndex}
          addCondition={addCondition}
          removeCondition={removeCondition}
          removeRow={removeRow}
          setInputValue={setInputValue}
        />
      );
    case ConditionOperationType.NumberIsInRange:
      return (
        <ConditionNumberInRangeAssignment
          rowData={rowData}
          index={rowIndex}
          conditionIndex={conditionIndex}
          addCondition={addCondition}
          removeCondition={removeCondition}
          removeRow={removeRow}
          setInputValue={setInputValue}
        />
      );
    case ConditionOperationType.StringEquals:
    case ConditionOperationType.StringArrayEquals:
    default:
      return (
        <ConditionStringEqualsAssignment
          rowData={rowData}
          rowIndex={rowIndex}
          conditionIndex={conditionIndex}
          addCondition={addCondition}
          removeCondition={removeCondition}
          removeRow={removeRow}
          setInputValue={setInputValue}
        />
      );
  }
};

const EditAssignmentTemplate = ({
  rule,
  editValues,
  setEditValues,
  updateDefaultEditValue,
  onEditClick,
}: {
  rule: RuleGroupDisplayDto;
  editValues: AssignmentRowDtoWithKey[];
  setEditValues: any;
  updateDefaultEditValue: any;
  onEditClick: any;
}) => {
  const [openToast, setToast] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const removeRow = (index: number) => {
    if (index > -1) {
      editValues.splice(index, 1);

      if (editValues.length === 0) {
        var ruleGroupAssignmentDto: AssignmentRowDtoWithKey =
          new AssignmentRowDtoWithKey({
            modificationValue: defaultModificationValue(
              rule?.template?.modificationOperationId
            ),
            key: uuidv4(),
            modificationOperationId: rule?.template?.modificationOperationId,
            conditions:
              rule?.template?.conditions?.map(
                (c) =>
                  new RuleAssignmentConditionDto({
                    conditionValues: defaultConditionValues(
                      c.conditionOperationId
                    ),
                    conditionFieldId: c.conditionFieldId ?? 0,
                    conditionOperationId: c.conditionOperationId ?? 0,
                    fieldName: c.conditionField,
                  })
              ) ?? [],
          });
        editValues.push(ruleGroupAssignmentDto);
      }
      setEditValues(editValues);
    }
  };

  const removeCondition = (
    condition: string,
    rowIndex: number,
    conditionIndex: number
  ): string[] => {
    const i: number =
      editValues[rowIndex].conditions[conditionIndex].conditionValues?.indexOf(
        condition
      ) ?? 0;
    editValues[rowIndex].conditions[conditionIndex].conditionValues?.splice(
      i,
      1
    );
    setEditValues(editValues);
    return (
      editValues[rowIndex].conditions[conditionIndex].conditionValues ?? []
    );
  };

  let conditionValues: Array<any> = [];

  // editValues?.map((value: any) => {
  //   conditionValues = [...conditionValues, ...value?.conditionValues];
  // });

  const addCondition = (
    condition: string,
    rowIndex: number,
    conditionIndex: number
  ) => {
    setToast(false);
    const found = conditionValues.includes(condition);

    if (editValues[rowIndex].conditions == null) {
      return;
    }

    const rowData: RuleAssignmentConditionDto =
      editValues[rowIndex].conditions[conditionIndex];

    switch (rowData.conditionOperationId) {
      case ConditionOperationType.Boolean:
      case ConditionOperationType.DateIsInRange:
      case ConditionOperationType.NumberIsInRange:
        rowData.conditionValues = [condition];
        //setEditValues(editValues);
        break;
      case ConditionOperationType.StringContains:
      case ConditionOperationType.StringEquals:
      case ConditionOperationType.StringStartsWith:
      case ConditionOperationType.StringArrayContains:
      case ConditionOperationType.StringArrayEquals:
      case ConditionOperationType.StringArrayStartsWith:
      default: {
        if (condition.trim().length) {
          if (!found) {
            rowData.conditionValues?.push(condition);
            setEditValues(editValues);
          } else {
            setToast(true);
          }
        }
        break;
      }
    }
  };

  const changeRowModificationValue = (index: number, newModValue: string) => {
    if (index === -1) {
      // default
      updateDefaultEditValue(newModValue);
    } else {
      editValues[index].modificationValue = newModValue;
      setEditValues(editValues);
    }
  };

  const addAssignment = () => {
    var ruleGroupAssignmentDto: AssignmentRowDtoWithKey =
      new AssignmentRowDtoWithKey({
        modificationValue: defaultModificationValue(
          rule?.template?.modificationOperationId
        ),
        key: uuidv4(),
        modificationOperationId: rule?.template?.modificationOperationId,
        conditions:
          rule?.template?.conditions?.map(
            (c) =>
              new RuleAssignmentConditionDto({
                conditionValues: defaultConditionValues(c.conditionOperationId),
                conditionFieldId: c.conditionFieldId ?? 0,
                conditionOperationId: c.conditionOperationId ?? 0,
                fieldName: c.conditionField,
              })
          ) ?? [],
      });
    editValues.push(ruleGroupAssignmentDto);
    setEditValues([...editValues]);

    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    });
  };

  const defaultConditionValues = (operationId: number | null | undefined) => {
    switch (operationId) {
      case ConditionOperationType.Boolean:
        return ["false"];
      case ConditionOperationType.DateIsInRange:
      case ConditionOperationType.NumberIsInRange:
      default:
        return [];
    }
  };

  const defaultModificationValue = (operationId?: number) => {
    switch (operationId) {
      case ModificationOperationType.AddMarkup:
        return `{ "amount": "0", "type": "percent" }`;
      case ModificationOperationType.MarkupFlat:
      case ModificationOperationType.MarkupPercent:
        return "0";
      default:
        return "";
    }
  };

  const ConditionColumns = () => {
    return 8;
  };
  const ModificationColumns = () => {
    return 3;
  };

  return (
    <Grid item container spacing={4}>
      {openToast && (
        <ToastAlert
          open={openToast}
          setToast={setToast}
          errorMsg={`${inputValue} is already used in this rule.`}
        />
      )}
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={1} />
        <Grid item xs={ConditionColumns() - 1} md={ConditionColumns()}>
          <Typography color="primary.main">
            Conditions
            {/* {rule?.template?.conditions?.[0].conditionField} */}
          </Typography>
        </Grid>
        <Grid item xs={ModificationColumns() + 1} md={ModificationColumns()}>
          <Typography color="secondary.main" sx={{ marginLeft: 8 }}>
            {rule?.template?.modificationOperation}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container spacing={4}>
        {rule?.defaultAssignment && (
          <Grid item container direction="row" spacing={2}>
            <Grid
              item
              container
              xs={1}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              wrap="nowrap"
            >
              <Grid item ml={2}>
                <Chip
                  sx={{
                    height: 25,
                    backgroundColor: "primary.main",
                    color: "white",
                  }}
                  label="default"
                />
              </Grid>
              <Grid item></Grid>
            </Grid>
            <Grid
              item
              container
              xs={ConditionColumns() - 1}
              md={ConditionColumns()}
              alignItems="center"
            >
              When none of the conditions below apply
            </Grid>

            <Grid
              item
              container
              xs={ModificationColumns() + 1}
              md={ModificationColumns()}
              alignItems="center"
              sx={{
                wrap: "nowrap",
              }}
            >
              <RowModificationAssignment
                rowData={rule?.defaultAssignment}
                changeRowModificationValue={changeRowModificationValue}
                index={-1}
                numOfAssignments={editValues.length}
                addAssignment={addAssignment}
              />
            </Grid>
          </Grid>
        )}
        {rule?.defaultAssignment && (
          <Divider sx={{ backgroundColor: "info.main", marginTop: 2 }} />
        )}
        {editValues?.map(
          (assignment: AssignmentRowDtoWithKey, rowIndex: number) => {
            return (
              <Grid
                item
                container
                direction="row"
                spacing={2}
                key={assignment.key}
              >
                <Grid
                  item
                  container
                  xs={1}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  wrap="nowrap"
                >
                  <Grid item ml={2}>
                    <Avatar
                      sx={{
                        width: 25,
                        height: 25,
                        backgroundColor: "primary.main",
                        verticalAlign: "",
                      }}
                    >
                      <Typography
                        color="white"
                        fontSize="small"
                        sx={{ lineHeight: "unset" }}
                      >
                        {rowIndex + 1}
                      </Typography>
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="delete"
                      onClick={() => removeRow(rowIndex)}
                    >
                      <CloseIcon
                        fontSize="small"
                        sx={{
                          color: "info.light",
                        }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  sx={{ pt: "5px" }}
                  xs={ConditionColumns() - 1}
                  md={ConditionColumns()}
                >
                  {assignment?.conditions?.map((condition, conditionIndex) => {
                    return (
                      <Grid
                        item
                        container
                        key={uuidv4()}
                        alignItems="center"
                        sx={{ mt: conditionIndex > 0 ? 3 : 0 }}
                      >
                        <Grid item sx={{ flexGrow: 1 }}>
                          <ConditionEditTemplate
                            rowData={assignment}
                            rowIndex={rowIndex}
                            conditionIndex={conditionIndex}
                            setInputValue={setInputValue}
                            addCondition={addCondition}
                            removeCondition={removeCondition}
                            removeRow={removeRow}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>

                <Grid
                  item
                  container
                  xs={ModificationColumns() + 1}
                  md={ModificationColumns()}
                  alignItems="center"
                  sx={{
                    wrap: "nowrap",
                  }}
                >
                  <RowModificationAssignment
                    rowData={assignment}
                    changeRowModificationValue={changeRowModificationValue}
                    index={rowIndex}
                    numOfAssignments={editValues.length}
                    addAssignment={addAssignment}
                  />
                </Grid>

                <Divider sx={{ backgroundColor: "info.main", marginTop: 2 }} />
              </Grid>
            );
          }
        )}
        <Grid item alignItems="center">
          <Grid item container alignItems="center">
            <Grid item>
              <IconButton size="medium" onClick={addAssignment}>
                <AddCircleIcon
                  sx={{ color: "primary.main", width: 30, height: 30 }}
                />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography sx={{ color: "info.light" }}>Add row</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditAssignmentTemplate;
