import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import RuleCard from "./RuleCard";
import {
  RulesApi,
  RuleGroupDisplayDto,
} from "../../../Api/SocketRulesEngineApi.generated";
import { useOktaAuth } from "@okta/okta-react";
import { Button, Paper, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useFlags } from "launchdarkly-react-client-sdk";

const Rules = ({ redirectPage }: any) => {
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const rulesApi = new RulesApi();
  const launchDarklyFeatureFlag = useFlags();
  const [rules, setRules] = useState<RuleGroupDisplayDto[]>([]);

  const getRules = async () => {
    rulesApi.token = token;
    let rules = await rulesApi.getRuleGroups();
    return rules;
  };

  useEffect(() => {
    const fetchData = async () => {
      const rules = await getRules();
      setRules(rules);
    };
    fetchData();
  }, []);

  const newCard = rules?.map((rule: RuleGroupDisplayDto) => {
    return (
      <Grid item xs={12} sx={{ mb: 3 }} key={rule.url}>
        <RuleCard rule={rule} redirectPage={redirectPage} />
      </Grid>
    );
  });

  return (
    <Grid item container xs={12} width="100%">
      <Paper
        elevation={10}
        sx={{
          padding: "40px 40px 30px 40px",
          width: "100%",
          minHeight: "calc(100vh - 120px)",
        }}
      >
        <Grid item container spacing={5}>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography variant="h4">Rules</Typography>
            </Grid>
            {launchDarklyFeatureFlag["sru-create-rule-group-button"] && (
              <Grid item xs={6} container justifyContent="flex-end">
                <Tooltip title="Create a new Rule" arrow>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                      minWidth: "auto",
                    }}
                    onClick={() => {
                      redirectPage("/rule/new");
                    }}
                  >
                    <AddIcon />
                  </Button>
                </Tooltip>
              </Grid>
            )}
          </Grid>
          {newCard}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Rules;
