import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Snackbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import Paper from "@mui/material/Paper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Spinner from "./loading/Spinner";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

type ToastProps = {
  open: boolean;
  errorMsg: string;
  route?: string;
};
const ToastAlert = ({ open, errorMsg, setToast, route }: any) => {
  const history = useHistory();
  const handleSnackBarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setToast(false);
  };
  const handleRedirect = () => {
    if (route.length > 0) {
      history.push(route);
    }
  };

  const action = (
    <IconButton onClick={handleSnackBarClose}>
      <CloseIcon fontSize="small" sx={{ color: "white" }} />
    </IconButton>
  );

  return (
    <>
      {route?.length > 0 && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              color: "white",
            }}
            onClick={handleRedirect}
            startIcon={<ArrowBackIcon />}
          >
            back to rules
          </Button>
        </Grid>
      )}

      <Snackbar
        ContentProps={{
          sx: {
            background: "#E37A41",
            color: "white",
            opacity: 0.7,
          },
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={open}
        action={action}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
        message={errorMsg}
      />
    </>
  );
};

export default ToastAlert;
