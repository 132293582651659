import React from "react";
import Grid from "@mui/material/Grid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import { Select, MenuItem } from "@mui/material";
import { AssignmentRowDtoWithKey } from "../../../../../../models/AssignmentRowDtoWithKey";

const ModificationNumericAssignment = ({
  rowData,
  onChange,
}: {
  rowData: AssignmentRowDtoWithKey;
  onChange: any;
}) => {
  return (
    <Grid item>
      <TextField
        id="standard-basic"
        type="number"
        defaultValue={rowData.modificationValue}
        onChange={(event) => onChange(event.target.value)}
        sx={{ width: 85, fieldset: { borderColor: "info.main" } }}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
    </Grid>
  );
};

export default ModificationNumericAssignment;
