import List from "@mui/material/List";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";

export const PageContent = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  flexGrow: 1,
  padding: "65px 20px 0px 5px",
  // padding: "3% 4% 4% .5%",
  // transition: theme.transitions.create("margin", {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.leavingScreen,
  // }),
  // marginLeft: `-${drawerwidth}px`,
  // ...(open && {
  //     transition: theme.transitions.create("margin", {
  //         easing: theme.transitions.easing.easeOut,
  //         duration: theme.transitions.duration.enteringScreen,
  //     }),
  //     marginLeft: 0,
  // }),
}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  // padding: theme.spacing(0, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

export const StyledList = styled(List)(({ theme }) => ({
  // selected and (selected + hover) states
  "&& .Mui-selected, && .Mui-selected:hover": {
    backgroundColor: theme.palette.info.dark,
    borderRadius: "10px",
    "&, & .MuiListItemIcon-root": {
      color: theme.palette.secondary.light,
    },
    "&, & .MuiTypography-root": {
      color: "white",
      fontWeight: "bold",
    },
  },
  // hover states
  "& .MuiListItemButton-root:hover": {
    backgroundColor: theme.palette.info.dark,
    borderRadius: "10px",
  },
  "& .MuiListItemButton-root": {
    marginBottom: 10,
    width: 172,
    maxWidth: 225,
  },
}));

export const StyledNotificationMessageListItem = styled(List)(({ theme }) => ({
  "&& .Mui-selected, && .Mui-selected:hover": {
    backgroundColor: "#eceeff",
    borderRadius: "10px",
    // "&, & .MuiListItemIcon-root": {
    //     color: theme.palette.secondary.light,
    // },
    // "&, & .MuiTypography-root": {
    //     color: "white",
    //     fontWeight: "bold",
    // },
  },
  // hover states
  "& .MuiListItemButton-root:hover": {
    backgroundColor: "#eceeff",
    borderRadius: "10px",
  },
}));
