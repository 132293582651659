import React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import tunnelImage from "../../../assets/blue-tunnel.jpeg";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

const useStyles = makeStyles((theme) => ({
  backgroundImg: {
    backgroundImage: `url(${tunnelImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    minHeight: "100vh",
    paddingBottom: 45,
  },
  card: {
    width: 600,
    padding: 25,
  },
}));

const PageNotFound = ({ redirectPage }: any) => {
  const classes = useStyles();

  return (
    <Grid
      container
      m={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.backgroundImg}
    >
      <Card
        className={classes.card}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        }}
      >
        <CardContent>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            spacing={6}
          >
            <Grid item>
              <WarningAmberRoundedIcon
                sx={{
                  width: "85px",
                  height: "85px",
                }}
                color="secondary"
              />
            </Grid>
            <Grid item>
              <Typography variant="h2" color="white">
                Page Not Found
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => redirectPage("/")}
              >
                Go Back
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PageNotFound;
