import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { theme } from "../../../../../global/styles/theme";
import { AssignmentRowDtoWithKey } from "../../../../../../models/AssignmentRowDtoWithKey";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

const ConditionDateInRangeAssignment = ({
  rowData,
  addCondition,
  removeCondition,
  index,
  conditionIndex,
  setInputValue,
  removeRow,
}: {
  rowData: AssignmentRowDtoWithKey;
  addCondition: (
    condition: string,
    rowIndex: number,
    conditionIndex: number
  ) => void;
  removeCondition: any;
  index: any;
  conditionIndex: number;
  setInputValue: any;
  removeRow: any;
}) => {
  const [min, setMin] = useState<Date | null>(null);
  const [max, setMax] = useState<Date | null>(null);

  const setValue = (val: Date | null, type: string) => {
    const dateString =
      val != null ? DateTime.fromJSDate(val).toISODate() : null;

    if (type === "min") {
      setMin(val);
      addCondition(
        JSON.stringify({
          min: dateString,
          max: max != null ? DateTime.fromJSDate(max).toISODate() : null,
        }),
        index,
        conditionIndex
      );
    } else if (type === "max") {
      setMax(val);
      addCondition(
        JSON.stringify({
          min: min != null ? DateTime.fromJSDate(min).toISODate() : null,
          max: dateString,
        }),
        index,
        conditionIndex
      );
    }
  };

  useEffect(() => {
    const data = JSON.parse(
      rowData?.conditions?.[conditionIndex].conditionValues?.[0] ?? "{}"
    );

    const dMin = DateTime.fromISO(data?.min);
    const dMax = DateTime.fromISO(data?.max);
    setMin(dMin.isValid ? dMin.toJSDate() : null);
    setMax(dMax.isValid ? dMax.toJSDate() : null);
  }, [rowData]);

  return (
    <Grid item container className="container" alignItems="center" spacing={1}>
      <Chip
        label={rowData.conditions[conditionIndex].fieldName}
        size="small"
        sx={{
          backgroundColor: theme.palette.primary.light,
          color: "white",
          mx: 1,
          mt: "5px",
        }}
      />
      <Typography sx={{ pr: 2 }}>on or between</Typography>
      <DatePicker
        label="Start Date"
        value={min}
        onChange={(newValue) => setValue(newValue, "min")}
        slotProps={{
          actionBar: {
            actions: ["clear"],
          },
        }}
      ></DatePicker>

      <Typography sx={{ px: 2 }}>and</Typography>
      <DatePicker
        label="End Date"
        value={max}
        onChange={(newValue) => setValue(newValue, "max")}
        slotProps={{
          actionBar: {
            actions: ["clear"],
          },
        }}
      ></DatePicker>
    </Grid>
  );
};

export default ConditionDateInRangeAssignment;
