import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  progress: {
    position: "absolute",
    zIndex: 99999,
  },
  spinnerOverlay: {
    position: "fixed",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: 99999,
  },
}));
const Spinner = () => {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      className={classes.spinnerOverlay}
    >
      <CircularProgress
        className={classes.progress}
        size={80}
        thickness={5}
        sx={{ color: "secondary.light" }}
      />
    </Grid>
  );
};

export default Spinner;
