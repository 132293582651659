import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AssignmentRowDtoWithKey } from "../../../../../../models/AssignmentRowDtoWithKey";
import { Paper, Typography } from "@mui/material";
import { ConditionOperationType } from "../../../../../../utilities/OperationTypes";
import { v4 as uuidv4 } from "uuid";
import Chip from "@mui/material/Chip";
import { theme } from "../../../../../global/styles/theme";

const ConditionStringEqualsAssignment = ({
  rowData,
  addCondition,
  removeCondition,
  rowIndex,
  conditionIndex,
  setInputValue,
  removeRow,
}: {
  rowData: AssignmentRowDtoWithKey;
  addCondition: (
    condition: string,
    rowIndex: number,
    conditionIndex: number
  ) => void;
  removeCondition: any;
  rowIndex: number;
  conditionIndex: number;
  setInputValue: any;
  removeRow: any;
}) => {
  const [input, setInput] = useState("");

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      setInputValue(input);
      event.preventDefault();
      addCondition(input, rowIndex, conditionIndex);
      setInput("");
    }
  };

  const onBlur = (event: React.FocusEvent<HTMLElement>) => {
    setInputValue(input);
    event.preventDefault();
    addCondition(input, rowIndex, conditionIndex);
    setInput("");
  };

  const handleDelete = (condition: string) => {
    removeCondition(condition, rowIndex, conditionIndex);
  };

  return (
    <Grid
      item
      container
      className="container"
      alignItems="center"
      spacing={1}
      sx={{ flexFlow: "row" }}
    >
      <Chip
        label={rowData.conditions[conditionIndex].fieldName}
        size="small"
        sx={{
          backgroundColor: theme.palette.primary.light,
          color: "white",
          mx: 1,
          mt: "5px",
        }}
      />
      {rowData.conditions[conditionIndex].conditionOperationId ===
        ConditionOperationType.StringContains && (
        <Grid item sx={{ pr: 2 }}>
          <Typography>Contains:</Typography>
        </Grid>
      )}
      {rowData.conditions[conditionIndex].conditionOperationId ===
        ConditionOperationType.StringEquals && (
        <Grid item sx={{ pr: 2 }}>
          <Typography>Matches:</Typography>
        </Grid>
      )}
      {rowData.conditions[conditionIndex].conditionOperationId ===
        ConditionOperationType.StringStartsWith && (
        <Grid item sx={{ pr: 2 }}>
          <Typography>Starts with:</Typography>
        </Grid>
      )}
      <Grid item sx={{ flexGrow: 1 }}>
        <Paper
          variant="outlined"
          sx={{
            borderRadius: 1,
            padding: 2.25,
            borderColor: "info.main",
          }}
        >
          <Grid
            item
            container
            className="container"
            alignItems="center"
            spacing={1}
          >
            {rowData.conditions?.[conditionIndex].conditionValues?.map(
              (condition: string) => (
                <Grid item alignItems="center" key={uuidv4()}>
                  <Chip
                    label={condition}
                    size="medium"
                    variant="outlined"
                    onDelete={() => handleDelete(condition)}
                  />
                </Grid>
              )
            )}
            <Grid item>
              <TextField
                id="standard-basic"
                variant="standard"
                value={input}
                placeholder="Enter code"
                onKeyDown={onKeyDown}
                onChange={onChange}
                onBlur={onBlur}
                sx={{ maxWidth: 600 }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ConditionStringEqualsAssignment;
