import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
// import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import RouterPrompt from "../dialogs/RouterPrompt";
import { RulesApi } from "../../../../Api/SocketRulesEngineApi.generated";
import { theme } from "../../../global/styles/theme";
import { useHistory } from "react-router-dom";
import { useUserContext } from "../../../../context/providers/UserProvider";
import { TextField } from "@mui/material";
import RouteLeavingGuard from "../dialogs/RouteLeavingGuard";

const useStyles = makeStyles({
  ruleDefaultContainer: {
    margin: "5px",
    // padding: "10px",
    padding: "25px 25px 25px 25px",
  },
  inputClass: {},
});

const RuleDefaultAssignmentCard = ({ rule, setRuleDto }: any) => {
  const classes = useStyles();

  const history = useHistory();
  const rulesApi = new RulesApi(); // creates instance of RulesApi and methods found in SocketRulesEngineApi.generated.ts
  const { token } = useUserContext();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [updatedAmount, setAmount] = useState(
    rule.ruleAssignments?.[0].modificationValue
  );

  const handleEditCancel = () => {
    // Reset the editable value.
    setAmount(rule.ruleAssignments?.[0].modificationValue);
    setIsEditing(!isEditing);
  };

  const renderButtons = () => {
    if (isEditing) {
      return (
        <Grid item>
          <Button
            variant="text"
            sx={{ color: "info.main", marginRight: 2 }}
            onClick={handleEditCancel}
          >
            <Typography sx={{ fontSize: "10pt" }}>Cancel</Typography>
          </Button>
          <Button
            variant="contained"
            onClick={() => onEditClick()}
            color={isEditing ? "secondary" : "primary"}
            sx={{
              color: "white",
            }}
          >
            Save
          </Button>
        </Grid>
      );
    }
    return (
      <Button
        variant="contained"
        onClick={() => onEditClick()}
        color={isEditing ? "secondary" : "primary"}
        sx={{
          color: "white",
        }}
      >
        Edit
      </Button>
    );
  };

  const onEditClick = async () => {
    if (
      isEditing &&
      rule.ruleAssignments?.[0].modificationValue !== updatedAmount
    ) {
      setIsLoading(true);
      rulesApi.token = token;
      await rulesApi.transformOptions({});
      rulesApi
        .updateDefaultMarkup(rule.id, updatedAmount)
        .then((ruleGroup) => {
          setRuleDto(ruleGroup);
          setIsLoading(false);
          setIsEditing(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      setIsEditing(!isEditing);
    }
  };

  useEffect(() => {
    //getRuleInformation();
  }, [rule, isEditing, isLoading]);

  return (
    <Paper elevation={5} className={classes.ruleDefaultContainer}>
      <RouteLeavingGuard when={isEditing} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container alignItems="center">
        <Grid item xs={8}>
          <Typography variant="h6" sx={{ color: theme.palette.info.main }}>
            Default Rule
          </Typography>
        </Grid>
        <Grid container justifyContent="flex-end" item xs={4}>
          {renderButtons()}
        </Grid>
        <Grid container sx={{ mt: 2, mb: 3 }}>
          <Grid item xs={1} />
          <Grid item xs={11} sx={{ display: "flex", alignItems: "center" }}>
            If no other rules apply, then
            <Typography
              component="span"
              sx={{
                color: theme.palette.secondary.light,
                fontWeight: "bold",
                mx: 1,
              }}
            >
              apply
            </Typography>
            the following
            <Chip
              label={rule?.template?.modificationOperation}
              size="small"
              sx={{
                backgroundColor: theme.palette.secondary.main,
                color: "white",
                mx: 1,
              }}
            />
            to the
            <Typography
              component="span"
              sx={{
                color: theme.palette.secondary.light,
                fontWeight: "bold",
                ml: 1,
              }}
            >
              {rule?.template?.modificationField}
            </Typography>
            <Typography
              component="span"
              sx={{
                color: theme.palette.text.primary,
                // fontWeight: "bold",
                mr: isEditing ? 3 : 3,
              }}
            >
              :
            </Typography>
            {isEditing ? (
              <FormControl
                className={classes.inputClass}
                sx={{
                  width: "10ch",
                  textAlignLast: "center",
                  input: {
                    color: theme.palette.secondary.light,
                    height: "10px",
                    fontWeight: "bold",
                  },
                }}
                variant="outlined"
                size="small"
              >
                {/* <MarkupOutlinedInput
                  value={updatedAmount}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const val = Number(event.target.value);
                    const isNumber = Number.isFinite(val);
                    if (isNumber) {
                      setAmount(val);
                    }
                    setHasError(!isNumber);
                  }}
                  type="numeric"
                  disabled={!isEditing}
                  size="small"
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  error={hasError}
                  inputProps={{
                    "aria-label": "weight",
                  }}
                /> */}
                <TextField
                  type="number"
                  disabled={!isEditing}
                  value={updatedAmount}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  sx={{ fieldset: { borderColor: "info.main" } }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const val = event.target.value;
                    setAmount(val);
                    setHasError(false);
                  }}
                  error={hasError}
                />
              </FormControl>
            ) : (
              <Typography
                component="span"
                sx={{
                  color: theme.palette.secondary.light,
                  fontWeight: "bold",
                }}
              >
                {updatedAmount} %
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RuleDefaultAssignmentCard;
