import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { DrawerHeader, PageContent } from "./StyledNavComponents";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import metaforaLogo from "../../../assets/metafora-logo-orange-white.png";
import SideNavigationList from "./SideNavigationList";
import Typography from "@mui/material/Typography";

type SideNavigationProps = {
  theme: any;
  children: JSX.Element | Element[];
  redirectPage: (route: string) => void;
};

const useStyles = makeStyles({
  box: {
    display: "flex",
  },
  drawer: {
    width: 185,
    flexShrink: 0,
  },
  typography: {
    color: "white",
    fontSize: "normal",
  },
  metaforaLogo: {
    height: 40,
    width: 130,
    marginTop: 10,
  },
});

const SideNavigationDrawer = ({
  theme,
  children,
  redirectPage,
}: SideNavigationProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Drawer
        PaperProps={{
          style: {
            border: "none",
            width: 185,
            padding: "0px 0px 20px 9px",
            backgroundColor: theme.palette.primary.main,
          },
        }}
        variant="permanent"
        anchor="left"
        open
        className={classes.drawer}
      >
        <DrawerHeader />
        <SideNavigationList redirectPage={redirectPage} />
        <Grid item container direction="column" justifyContent="center">
          <Grid item container justifyContent="center">
            <Typography variant="overline" className={classes.typography}>
              Powered by
            </Typography>
          </Grid>
          <Grid item container justifyContent="center">
            <Box
              component="img"
              alt="Metafora logo"
              src={metaforaLogo}
              className={classes.metaforaLogo}
            />
          </Grid>
        </Grid>
      </Drawer>

      <PageContent theme={theme}>{children}</PageContent>
    </Box>
  );
};

export default SideNavigationDrawer;
