import axios from "axios";
const { REACT_APP_ISSUER } = process.env;

export const requestUserProfile = async (accessToken) => {
  const userProfile = await axios({
    url: `${REACT_APP_ISSUER}/v1/userinfo`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return userProfile.data;
};
