import React, { useEffect, useState } from "react";
import RuleDetailTitle from "./RuleDetailTitle";
import {
  IRuleGroupDisplayDto,
  RulesApi,
  RuleGroupDisplayDto,
} from "../../../../Api/SocketRulesEngineApi.generated";
import RuleTemplateCard from "../rulecards/RuleTemplateCard";
import RuleDefaultCard from "../rulecards/RuleDefaultCard";
import RuleAssignmentCard from "../rulecards/RuleAssignmentCard";
import RuleDefaultAssignmentCard from "../rulecards/RuleDefaultAssignmentCard";
import { Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useUserContext } from "../../../../context/providers/UserProvider";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import Spinner from "../../../global/loading/Spinner";
import ToastAlert from "../../../global/ToastAlert";

const useStyles = makeStyles({
  arrowLine: {
    margin: "10px auto 10px auto",
    textAlign: "center",
  },
  arrowHead: {
    margin: "10px auto 10px auto",
    textAlign: "center",
  },
});

const RuleDetailSkeleton = ({ redirectPage }: any) => {
  const rulesApi = new RulesApi(); // creates instance of RulesApi and methods found in SocketRulesEngineApi.generated.ts
  const { token } = useUserContext();
  const { ruleGroupUrl } = useParams<{ ruleGroupUrl: string }>();

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [openToast, setToast] = useState(true);
  const [ruleDto, setRuleDto] = useState<IRuleGroupDisplayDto>(
    new RuleGroupDisplayDto()
  );

  //method for getting rule detail information by id
  const getRuleInformation = async () => {
    setLoading(true);
    rulesApi.token = token;
    await rulesApi.transformOptions({});
    rulesApi
      .getRuleGroup(ruleGroupUrl)
      .then((ruleGroup) => {
        setRuleDto(ruleGroup);
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    getRuleInformation();
  }, []);

  return loading ? (
    <Paper>
      <Spinner />
    </Paper>
  ) : error ? (
    <ToastAlert
      open={openToast}
      setToast={setToast}
      errorMsg={"Error loading rule..."}
      route={"/rules"}
    />
  ) : (
    <Grid item container xs={12}>
      <Paper
        elevation={10}
        sx={{
          padding: "40px 40px 30px 40px",
          width: "100%",

          minHeight: "calc(100vh - 120px)",
        }}
      >
        <Grid item container direction="column">
          <Grid item xs={12} mb={6}>
            <RuleDetailTitle
              ruleGroupId={ruleDto.id ?? 0}
              title={ruleDto.name ?? ""}
              description={ruleDto.description ?? ""}
              startDate={ruleDto.startDate ?? null}
              endDate={ruleDto.endDate ?? null}
              lastUpdatedBy={ruleDto.lastUpdatedUserName ?? ""}
              lastUpdatedDate={
                ruleDto.lastUpdatedDate?.toLocaleString(
                  DateTime.DATETIME_SHORT
                ) ?? ""
              }
              redirectPage={redirectPage}
            />
          </Grid>
          {ruleDto.isDefault ? (
            <RuleDefaultAssignmentCard
              rule={ruleDto}
              setRuleDto={setRuleDto}
            ></RuleDefaultAssignmentCard>
          ) : (
            <>
              <Grid item>
                <RuleTemplateCard rule={ruleDto} />
              </Grid>
              {ruleDto.defaultRuleGroup && (
                <Grid item className={classes.arrowLine}>
                  <svg
                    width="2"
                    height="35"
                    viewBox="0 0 2 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 0L1 34.6765"
                      stroke="#777B83"
                      strokeOpacity="0.5"
                      strokeWidth="1.5"
                    />
                  </svg>
                </Grid>
              )}
              {ruleDto.defaultRuleGroup && (
                <Grid item>
                  <RuleDefaultCard
                    rule={ruleDto.defaultRuleGroup}
                    redirectPage={redirectPage}
                  />
                </Grid>
              )}
              <Grid item className={classes.arrowHead}>
                <svg
                  width="12"
                  height="36"
                  viewBox="0 0 12 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.46967 35.2068C5.76256 35.4997 6.23744 35.4997 6.53033 35.2068L11.3033 30.4338C11.5962 30.1409 
                            11.5962 29.6661 11.3033 29.3732C11.0104 29.0803 10.5355 29.0803 
                            10.2426 29.3732L6 33.6158L1.75736 29.3732C1.46447 29.0803 0.989593 
                            29.0803 0.6967 29.3732C0.403806 29.6661 0.403806 30.1409 0.6967 
                            30.4338L5.46967 35.2068ZM5.25 6.31565e-08L5.25 34.6765L6.75 
                            34.6765L6.75 -6.31565e-08L5.25 6.31565e-08Z"
                    fill="#777B83"
                    fillOpacity="0.5"
                  />
                </svg>
              </Grid>
              <Grid item>
                <RuleAssignmentCard rule={ruleDto} setRuleDto={setRuleDto} />
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default RuleDetailSkeleton;
