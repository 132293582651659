import React, { useState, useEffect } from "react";
import Chip from "@mui/material/Chip";
import { theme } from "../../../../../global/styles/theme";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AssignmentRowDtoWithKey } from "../../../../../../models/AssignmentRowDtoWithKey";

const ConditionNumberInRangeAssignment = ({
  rowData,
  addCondition,
  removeCondition,
  index,
  conditionIndex,
  setInputValue,
  removeRow,
}: {
  rowData: AssignmentRowDtoWithKey;
  addCondition: (
    condition: string,
    rowIndex: number,
    conditionIndex: number
  ) => void;
  removeCondition: any;
  index: number;
  conditionIndex: number;
  setInputValue: any;
  removeRow: any;
}) => {
  const [min, setMin] = useState<string>(
    JSON.parse(
      rowData?.conditions?.[conditionIndex].conditionValues?.[0] ??
        `{"min":"", "max":""}`
    ).min
  );
  const [max, setMax] = useState<string>(
    JSON.parse(
      rowData?.conditions?.[conditionIndex].conditionValues?.[0] ??
        `{"min":"", "max":""}`
    ).max
  );

  const setValue = (val: string, type: string) => {
    if (type === "min") {
      setMin(val);
      addCondition(
        JSON.stringify({ min: val, max: max }),
        index,
        conditionIndex
      );
    } else if (type === "max") {
      setMax(val);
      addCondition(
        JSON.stringify({ min: min, max: val }),
        index,
        conditionIndex
      );
    }
  };

  return (
    <Grid item container className="container" alignItems="center" spacing={1}>
      <Chip
        label={rowData.conditions[conditionIndex].fieldName}
        size="small"
        sx={{
          backgroundColor: theme.palette.primary.light,
          color: "white",
          mx: 1,
          mt: "5px",
        }}
      />
      <Typography sx={{ pr: 2 }}>on or between</Typography>
      <TextField
        id="range-min"
        type="number"
        value={min}
        label="Minimum"
        onChange={(event) => {
          setValue(event.target.value, "min");
        }}
      />
      <Typography sx={{ px: 2 }}>and</Typography>
      <TextField
        id="range-max"
        type="number"
        value={max}
        label="Maximum"
        onChange={(event) => {
          setValue(event.target.value, "max");
        }}
      />
    </Grid>
  );
};

export default ConditionNumberInRangeAssignment;
