import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Input,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import { DatePicker } from "@mui/x-date-pickers";
import Spinner from "../../../global/loading/Spinner";
import { DateTime } from "luxon";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import {
  CreateRuleGroupDto,
  RuleBuilderFieldDto,
  RuleConditionDto,
  RulesApi,
} from "../../../../Api/SocketRulesEngineApi.generated";
import { useUserContext } from "../../../../context/providers/UserProvider";
import { useOktaAuth } from "@okta/okta-react";
import NewCondition from "./NewCondition";
import NewModification from "./NewModification";
import { Form, Formik, ErrorMessage, FormikProps } from "formik";
import { theme } from "../../../global/styles/theme";
import { NewRuleSchema } from "../../../../validation/NewRuleSchema";
import { DatePickerField } from "../../../global/forms/DatePickerField";

export interface INewRuleForm {
  title: string;
  description: string;
  isDefault: boolean;
  modificationId: string | undefined;
  modificationOperation: string | undefined;
  startDate: Date | null;
  endDate: Date | null;

  conditions: IRuleConditionForm[];
}

export interface IRuleConditionForm {
  conditionId: string | undefined;
  conditionOperation: string | undefined;
}

const initialFormValues: INewRuleForm = {
  title: "",
  description: "",
  isDefault: false,
  modificationId: "",
  modificationOperation: "",
  startDate: null,
  endDate: null,
  conditions: [{ conditionId: "", conditionOperation: "" }],
};

const useStyles = makeStyles({
  ruleTitleContainer: {
    margin: "5px",
    // padding: "10px",
    fontStyle: "normal",
    fontWeight: 300,
    letterSpacing: ".15px",
    fontSize: "24pt",
  },
  ruleTitleHeader: {
    fontSize: "24pt",
  },
  ruleTitleInfoContainer: {
    margin: "5px",
    padding: "10px",
    lineHeight: "35px",
  },
  title: {
    margin: "0 0 0 0",
    fontStyle: "normal",
    fontWeight: 300,
    letterSpacing: ".15px",
    fontSize: "24px",
  },
  description: {
    margin: "0 0 0 0",
    width: "100%",
    pointerEvents: "none",
    fontSize: "12px",
  },
  lastUpdated: {
    textAlign: "right",
    color: "#777B83",
    opacity: ".8",
  },
  ruleDefaultContainer: {
    margin: "5px",
    // padding: "10px",
    padding: "25px 25px 25px 25px",
  },
  errorMessage: {
    fontSize: "12px",
    color: "red",
  },
});

const NewRuleCard = ({ redirectPage }: any) => {
  const classes = useStyles();

  const rulesApi = new RulesApi();
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();

  const [conditions, setConditions] = useState<RuleBuilderFieldDto[]>([]);
  const [modifications, setModifications] = useState<RuleBuilderFieldDto[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getBuilderOptions = async () => {
    rulesApi.token = token;
    let options = await rulesApi.getRuleBuilderOptions();

    return options;
  };

  const fetchData = async () => {
    //setLoading(true);

    const options = await getBuilderOptions();

    setConditions(options.conditions ?? []);
    setModifications(options.modifications ?? []);

    //setLoading(false);
  };

  useEffect(() => {
    fetchData();
    console.log("new rule effect");
  }, []);

  console.log("new rule");

  const onSaveRule = async (rule: INewRuleForm) => {
    setIsLoading(true);
    rulesApi.token = token;
    rulesApi
      .createRuleGroup(
        new CreateRuleGroupDto({
          name: rule.title,
          description: rule.description,
          modificationFieldId: Number(rule.modificationId),
          modificationOperationId: Number(rule.modificationOperation),
          isDefault: false,
          startDate:
            rule.startDate !== null
              ? DateTime.fromJSDate(rule.startDate).setZone("utc", {
                  keepLocalTime: true,
                })
              : null,
          endDate:
            rule.endDate !== null
              ? DateTime.fromJSDate(rule.endDate).setZone("utc", {
                  keepLocalTime: true,
                })
              : null,
          conditions: rule.conditions.map(
            (c) =>
              new RuleConditionDto({
                conditionFieldId: Number(c.conditionId),
                conditionOperationId: Number(c.conditionOperation),
              })
          ),
        })
      )
      .then((result) => {
        const ruleRoute = `/rule/${result.url}`;
        redirectPage(ruleRoute);

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const addCondition = (form: FormikProps<INewRuleForm>) => {
    form.values.conditions.push({ conditionId: "", conditionOperation: "" });

    form.setFieldValue("conditions", form.values.conditions);
  };

  const removeCondition = (form: FormikProps<INewRuleForm>, index: number) => {
    if (index > -1 && index < form.values.conditions.length) {
      form.values.conditions.splice(index, 1);

      form.setFieldValue("conditions", form.values.conditions);
    }
  };

  return (
    <Grid item container xs={12}>
      <Paper
        elevation={10}
        sx={{
          padding: "40px 40px 30px 40px",
          width: "100%",

          minHeight: "calc(100vh - 120px)",
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Formik
          initialValues={initialFormValues}
          validationSchema={NewRuleSchema}
          onSubmit={(values, actions) => {
            onSaveRule(values);
          }}
        >
          {(formik) => (
            <Form>
              <Grid item container direction="column">
                <Grid item xs={12} mb={6}>
                  <Grid item container pb={5}>
                    <Typography variant="h4">Create Rule Group</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    className={classes.ruleTitleContainer}
                  >
                    <Grid
                      item
                      container
                      lg={12}
                      md={12}
                      sm={12}
                      justifyContent="space-between"
                    >
                      <Grid item xs={12}>
                        <Typography variant="h6" className={classes.title}>
                          Name your rule
                        </Typography>
                      </Grid>
                      <Grid item mt={2} xs={5}>
                        <TextField
                          fullWidth
                          variant="standard"
                          label="Name"
                          id="new-title"
                          name="title"
                          value={formik.values.title}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        ></TextField>
                      </Grid>
                      <Grid
                        item
                        mt={2}
                        xs={4}
                        container
                        justifyContent={"flex-end"}
                      >
                        <DatePickerField
                          name="startDate"
                          label="Start Date"
                          slotProps={{
                            actionBar: {
                              actions: ["clear"],
                            },
                          }}
                        ></DatePickerField>
                        <ErrorMessage
                          name="startDate"
                          className={classes.errorMessage}
                        />
                      </Grid>
                      <Grid item mt={2} xs={8}>
                        <TextField
                          fullWidth
                          variant="standard"
                          id="new-description"
                          name="description"
                          label="Description"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        ></TextField>
                      </Grid>

                      <Grid
                        item
                        mt={2}
                        xs={4}
                        container
                        justifyContent={"flex-end"}
                      >
                        <DatePickerField
                          name="endDate"
                          label="End Date"
                          slotProps={{
                            actionBar: {
                              actions: ["clear"],
                            },
                          }}
                        ></DatePickerField>
                        <Typography className={classes.errorMessage}>
                          <ErrorMessage name="endDate" />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Paper elevation={5} className={classes.ruleDefaultContainer}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} sx={{ pb: 4 }}>
                        <Typography
                          variant="h6"
                          sx={{ color: theme.palette.info.main }}
                        >
                          Rule Template
                        </Typography>
                      </Grid>
                      <Grid item container xs={5}>
                        {formik.values.conditions.map((c, index) => {
                          return (
                            <Grid
                              item
                              sx={{ mt: index > 0 ? 2 : 0 }}
                              xs={12}
                              key={`newCondition_${index}`}
                            >
                              <NewCondition
                                index={index}
                                options={conditions}
                                formik={formik}
                                removeCondition={removeCondition}
                              />
                            </Grid>
                          );
                        })}
                        {/* <NewCondition
                          index={0}
                          options={conditions}
                          formik={formik}
                        /> */}
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        Then
                        <Chip
                          label="apply"
                          size="small"
                          sx={{
                            backgroundColor: theme.palette.secondary.main,
                            color: "white",
                            mx: 1,
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <NewModification
                          options={modifications}
                          formik={formik}
                        />
                      </Grid>
                      <Grid item xs={5} sx={{ mt: 2 }} container>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={5} container alignItems="center">
                          <Grid item>
                            <IconButton
                              size="medium"
                              onClick={() => addCondition(formik)}
                            >
                              <AddCircleIcon sx={{ color: "primary.main" }} />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <Typography sx={{ color: "info.light" }}>
                              Add condition
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item container xs={12} mt={4} justifyContent={"end"}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={!(formik.isValid && formik.dirty)}
                    onClick={() => {}}
                    color={"secondary"}
                    sx={{
                      color: "white",
                    }}
                  >
                    Create Rule
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Grid>
  );
};

export default NewRuleCard;
