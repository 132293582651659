import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { IconButton } from "@mui/material";
import { DateTime } from "luxon";
import AssignmentBasedRulesList from "./AssignmentBasedRulesList";

const RuleCard = ({ rule, redirectPage }: any) => {
  const handleClick = () => {
    const ruleRoute = `/rule/${rule.url}`;
    redirectPage(ruleRoute);
  };

  return (
    <Card
      onClick={handleClick}
      sx={{ boxShadow: 3, width: 1, padding: "10px" }}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={8}>
            <Typography
              variant="h6"
              component="div"
              sx={{ textDecoration: "underline", cursor: "pointer" }}
            >
              {rule?.name}
              <IconButton>
                <OpenInNewIcon />
              </IconButton>
            </Typography>
            <Typography color="text.secondary" variant="subtitle2">
              {rule?.description}
            </Typography>
            <Typography color="text.secondary" variant="subtitle2">
              {rule?.startDate &&
                rule?.endDate &&
                `This Rule is only applies between ${rule?.startDate?.toLocaleString()} and ${rule?.endDate?.toLocaleString()}`}
              {rule?.startDate &&
                !rule?.endDate &&
                `This Rule is only applies after ${rule?.startDate?.toLocaleString()}`}
              {!rule?.startDate &&
                rule?.endDate &&
                `This Rule is only applies until ${rule?.endDate?.toLocaleString()}`}
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-end" item xs={4}>
            <Typography
              color="text.secondary"
              sx={{ lineHeight: 1.7 }}
              variant="body2"
              align="right"
            >
              Last updated by <br /> {rule?.lastUpdatedUserName} <br />
              {rule?.lastUpdatedDate?.toLocaleString(DateTime.DATETIME_SHORT)}
            </Typography>
          </Grid>
          {rule?.isDefault ? null : (
            <Grid item xs={12} mt={4} ml={3}>
              <AssignmentBasedRulesList rule={rule} />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RuleCard;
