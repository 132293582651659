import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

type RouteLeavingDialogTypes = {
  visible: boolean;
  onCancel: any;
  onConfirm: any;
};
const RouteLeavingDialog = ({
  visible,
  onCancel,
  onConfirm,
}: RouteLeavingDialogTypes) => {
  const confirm = () => {
    onCancel();
    onConfirm();
  };

  return (
    <Dialog open={visible}>
      <DialogTitle
        sx={{
          fontWeight: "bold",
          variant: "h5",
        }}
      >
        You have unsaved changes.
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "30px 30px 0px 30px",
        }}
      >
        <DialogContentText>
          Are you sure want to leave this page ?
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          padding: "25px",
          justifyContent: "space-between",
        }}
      >
        <Button
          sx={{
            fontWeight: "bold",
            color: "info.dark",
          }}
          onClick={() => onCancel()}
        >
          No, keep editing.
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            fontWeight: "bold",
            color: "white",
          }}
          onClick={confirm}
          autoFocus
        >
          Yes, leave page.
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RouteLeavingDialog;
