import React, { useState, useEffect } from "react";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { IconButton } from "@mui/material";
import { RuleGroupDisplayDto } from "../../../../Api/SocketRulesEngineApi.generated";
import { theme } from "../../../global/styles/theme";

const useStyles = makeStyles({
  ruleDefaultContainer: {
    margin: "5px",
    padding: "25px 25px 25px 25px",
  },
});

const RuleDefaultCard = ({ rule, redirectPage }: any) => {
  const [defaultRule] = useState<RuleGroupDisplayDto>(rule);

  useEffect(() => {
    //setDefaultRule(defaultRule);
  }, [defaultRule]);

  const classes = useStyles();
  return (
    <Paper elevation={5} className={classes.ruleDefaultContainer}>
      <Grid container>
        <Grid item xs={12}>
          <Typography>
            <AnnouncementIcon sx={{ color: "info.main" }} />
            &nbsp; An existing default rule affects the
            <Typography
              component="span"
              sx={{
                color: theme.palette.secondary.light,
                fontWeight: "bold",
              }}
            >
              {" "}
              {defaultRule?.template?.modificationField}{" "}
            </Typography>
            as well:
          </Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11} container alignItems="center" sx={{ mt: 2, mb: 2 }}>
          <Typography
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
              color: theme.palette.info.main,
            }}
            component="a"
            href={`rule/${defaultRule?.url}`}
          >
            {defaultRule?.name}
            <IconButton>
              <OpenInNewIcon />
            </IconButton>
          </Typography>
          <Typography>
            &nbsp; If no other rule assignments apply, the default
          </Typography>
          <Chip
            label={defaultRule?.template?.modificationOperation}
            size="small"
            sx={{
              backgroundColor: theme.palette.secondary.main,
              color: "white",
              mx: 1,
            }}
          />
          <Typography>
            will be &nbsp;
            <Typography
              component="span"
              sx={{
                color: theme.palette.secondary.light,
                fontWeight: "bold",
              }}
            >
              {defaultRule?.ruleAssignments?.map((rule) => {
                return rule?.modificationValue;
              })}{" "}
              %
            </Typography>
            .
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RuleDefaultCard;
