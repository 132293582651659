import { string, object, Schema, boolean, number, date, ref, array } from "yup";
import { INewRuleForm } from "../components/pages/rule-details/new/NewRuleCard";
import { DateTime } from "luxon";

function luxonTransform(
  value: any,
  originalValue: DateTime | null
): Date | null {
  if (!!originalValue) {
    return originalValue?.toJSDate();
  }
  return originalValue;
}

export const NewRuleSchema: Schema<INewRuleForm> = object({
  title: string().required("Rule Name is required").defined(),
  description: string().optional().default(""),
  isDefault: boolean().optional().default(false),
  //conditionId: string().required(),
  //conditionOperation: string().required(),
  modificationId: string().required(),
  modificationOperation: string().required(),
  startDate: date().nullable().default(null),
  endDate: date()
    .nullable()
    .when("startDate", (startDate, schema) => {
      //      console.log("startDate", startDate);
      if (startDate && !!startDate[0]) {
        //        console.log("validate");
        return schema.min(startDate, "End Date must be after Start Date");
      }
      return schema;
    })
    .default(null),
  conditions: array()
    .required()
    .of(
      object().shape({
        conditionId: string().required(),
        conditionOperation: string().required(),
      })
    ),
});
