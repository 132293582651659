export const formatNumber = (num: number | string) => {
  const parts = num.toString().split(".");
  const integerPart = parts[0];
  const decimalPart = parts.length > 1 ? parts[1] : "";

  let formattedDecimalPart = decimalPart.padEnd(integerPart.length, "0");
  if (formattedDecimalPart.length > 0) {
    formattedDecimalPart = "." + formattedDecimalPart;
  }

  return (
    "$" +
    integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    formattedDecimalPart
  );
};

export const formatCurrency = (num: number | string) => {
  const number = +num;
  return "$" + number.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
