export const enum ModificationOperationType {
  MarkupPercent = 1,
  MarkupFlat = 3,
  AddMarkup = 10,
}

export const enum ConditionOperationType {
  NumberIsInRange = 4,
  StringEquals = 2,
  StringContains = 5,
  StringStartsWith = 6,

  Boolean = 8,
  DateIsInRange = 9,

  StringArrayEquals = 11,
  StringArrayContains = 12,
  StringArrayStartsWith = 13,
}
